/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EndorsementRequestSearchFilter { 
    createdOnOrAfter?: string;
    createdBefore?: string;
    dentalProfessionalId?: number;
    endorserlId?: number;
    specializationCode?: string;
    id?: number;
    notificationSent?: boolean;
    dateReviewed?: string;
    dateDeactivated?: string;
    dateNotified?: string;
    endorsementStatus?: EndorsementRequestSearchFilter.EndorsementStatusEnum;
    comment?: string;
    limit?: number;
    status?: EndorsementRequestSearchFilter.StatusEnum;
    offset?: number;
}
export namespace EndorsementRequestSearchFilter {
    export type EndorsementStatusEnum = 'ACCEPTED' | 'DECLINED' | 'PENDING';
    export const EndorsementStatusEnum = {
        Accepted: 'ACCEPTED' as EndorsementStatusEnum,
        Declined: 'DECLINED' as EndorsementStatusEnum,
        Pending: 'PENDING' as EndorsementStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


