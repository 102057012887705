import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {
  EmailValidator,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  BwFileControllerService,
  DentalOfficeProfessionalControllerService,
  DentalOfficeProfessionalPojo,
  DentalProfessionalPojo,
  DentalReferralControllerService,
  DentalReferralCreationDto,
  SpecializationPojo,
  TemporaryDentalOffice,
  UploadFileRequestParams,
  ReferralDraft,
  ReferralDraftPojo,
  XrayPojo,
  DentalOfficeControllerService,
  DentalServiceProvided,
  InsuranceCompanyControllerService, InsuranceCompany, UserPojo, UserControllerService,
} from "dd-core-api-sdk";
import PriorityLevelConstantEnum = DentalReferralCreationDto.PriorityLevelConstantEnum;
import {Utils} from "../../../../shared/utils";
import {CountryISO, SearchCountryField, PhoneNumberFormat} from "ngx-intl-tel-input";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {ProcedureMasterRecordService} from "../../../../services/procedure-master-record.service";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {WizardComponent} from "angular-archwizard";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {HttpEventType} from "@angular/common/http";
import {
  SpecialistOfficeModalComponent
} from "../../../../shared/specialist-office-modal/specialist-office-modal.component";
import * as moment from "moment";
import {environment} from "../../../../../environments/environment";
import {AddReferralProfModalComponent} from "../add-referral-prof-modal/add-referral-prof-modal.component";
import {OptionSelectResponseComponent} from "../tooth-selection-warning/option-select-response.component";
import {PhoneNumberValidator} from "dd-shared-lib";
import {JoyrideService} from "ngx-joyride";
import TourRideStatusConstantEnum = UserPojo.TourRideStatusConstantEnum;
import {DeliverableEmailValidator} from "../../../../services/deliverable-email.validator";


@Component({
  selector: "app-create-referral",
  templateUrl: "./create-referral.component.html",
  styleUrls: ["./create-referral.component.css"],
  providers: [DeliverableEmailValidator]
})
export class CreateReferralComponent implements OnInit, OnChanges, AfterViewInit  {

  height: number = 70;

  @Input() isDraft: boolean;
  @Input() draft: ReferralDraftPojo;
  @Output() emmitNextClicked = new EventEmitter<any>();
  @Output() fileDeleted = new EventEmitter<any>()
  @Output() toothDeselected: EventEmitter<any> = new EventEmitter();
  @ViewChild(WizardComponent)
  public wizard!: WizardComponent;

  @Input() childTeethComponent: any;
  @Input() adultTeethComponent: any;
  @Input() affectedTeeth: string;
  @Input() draftFiles: Array<any>;

  formGroup: FormGroup;
  model: NgbDateStruct;
  now: Date = new Date();
  procedureList: any;
  priorityEnum = Utils.enumValues(PriorityLevelConstantEnum);
  priorityEnumStatus = PriorityLevelConstantEnum;
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;
  PhoneNumberFormat = PhoneNumberFormat;
  errorSubmission: boolean;
  dentalSpecializations: SpecializationPojo[];
  fileDoc: any = {};
  fileDocs: Array<any> = [];
  xrayFile: Array<any> = [];
  xrayUrl: Array<any> = [];
  showErrorMessageTrigger = false;
  errMessage = '';
  dentalProfessional: DentalProfessionalPojo;
  user: UserAccountKeycloak[];
  userAccount: UserKeycloak;
  professionalList: DentalOfficeProfessionalPojo[];
  fullAge: any;
  bwFileIdList: Array<any> = [];
  showAdultTeeth: boolean = true

  proceduresTiles = [
    "Consultation",
    "Treatment",
    "Pulp Regeneration",
    "Revascularization",
    "Extractions",
    "Veneers",
    "Filings",
    "Crowns",
    "Root Canals",
    "Braces/Invisalign"
  ]
  dentalServicesTiles = [
    "Consultation",
    "Treatment",
  ]
  temporaryOffice: TemporaryDentalOffice;
  progress = 0;
  message = '';
  referralRequestId: number;
  manualDentalProfessionalFirstName: string;
  manualDentalProfessionalLastName: string;
  formatDate: any;
  loadingDentalServices: boolean;
  dentalServicesOptions: string[];
  insuranceCompanyOptions: Array<InsuranceCompany>;

  constructor(
    private fb: FormBuilder,
    private modalService: BsModalService,
    private router: Router, private readonly joyrideService: JoyrideService,
    private procedureMasterRecordService: ProcedureMasterRecordService,
    private dentalReferralController: DentalReferralControllerService,
    private dentalOfficeProfessional: DentalOfficeProfessionalControllerService,
    private dentalOfficeControllerService: DentalOfficeControllerService,
    private authentication: AuthenticationServiceKeycloak,
    private insuranceCompanyController: InsuranceCompanyControllerService,
    private dentalReferralControllerService: DentalReferralControllerService,
    private _activeRoute: ActivatedRoute,
    private deliverableEmailValidator: DeliverableEmailValidator,
    private userControllerService: UserControllerService,
    private bwFileController: BwFileControllerService,
    private elRef: ElementRef, private renderer: Renderer2
  ) {
    this.priorityEnum = this.priorityEnum.filter(v => v != 'SEMI_URGENT');

    router.events.subscribe(event => {
      this.getFromName();
      if (event instanceof NavigationStart) {
        this.emmitNextClicked.emit({
          formGroup: this.formGroup,
          files: this.fileDocs,
          officeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
        })
      }
    })

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGroup)
      this.formGroup.patchValue({
        affectedToothRange: this.affectedTeeth
      })
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.user = res.accounts;
      this.userAccount = res;
      if (this.userAccount?.dentalProfessional) {
        this.router.navigate(['/dashboard'])
      }
    })
    this.initForm();

    this.formGroup.get('dateOfBirth').valueChanges.subscribe(value => {

      this.formatDate = value;
      const currentDD = moment(moment(value).format('MM-DD-YYYY'));
      if ((currentDD > moment(new Date()).subtract('month', 6))) {

        window.scrollTo(0, 0);
        this.fullAge = '';
        this.showErrorMessage('Minimum age is 6 months.');
        this.formGroup.patchValue({dateOfBirth: ''});
      }

      if (!currentDD.isValid()) {
        this.formGroup.controls['dateOfBirth'].setErrors({'failedFormat': true});
      } else {
        this.formGroup.controls['dateOfBirth'].setErrors(null);
      }

      this.getAge(new Date(value));
    });

  }

  initForm() {
    this.formGroup = this.fb.group({
      patientFirstName: [this.isDraft ? this.draft?.patientFirstName : '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      patientLastName: [this.isDraft ? this.draft?.patientLastName : '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      dateOfBirth: [null, [Validators.required]],
      patientInsurance: ['', []],
      phoneNumber: [this.isDraft ? this.draft.phoneNumber : '', [Validators.required]],
      procedureType: [this.getProcedures(this.draft?.procedureTypes), [Validators.required]],
      referralNote: [this.isDraft ? this.draft.referralNote : '', [Validators.maxLength(500)]],
      preMedRequired: [this.isDraft ? this.draft.preMedRequired : false],
      priorityLevel: [this.isDraft ? this.draft.priority : this.priorityEnumStatus.Normal, [Validators.required]],
      affectedToothRange: [''],
      referralTo: [this.isDraft ? this.draft.referralTo : '', [Validators.required]],
      externalReferralOfficeEmail: ['', {
        Validators: [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$")],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
      externalReferralOfficePhone: ['', [PhoneNumberValidator()]],
      referralToProfValue: [this.isDraft ? this.draft.referralToProfId : ''],
      referralToOfficeValue: [this.isDraft ? this.draft.referralToOfficeId : ''],
      xray: ['', []],
      xrayFileName: ['', []],
      xrayFileType: ['', []],
      xrayDate: ['', []],
      specializationId: [''],
      referralToOfficeEmail: [''],
      referralToOfficeAddress: [''],
      referralToOfficeName: [''],
      referralFrom: ['', [Validators.required]],
      referredBy: ['', []],
      portalAccount: ['', []],
      uploadedFiles: ['', []],
      temporaryOfficeId: ['', []],
      temporaryOfficeName: ['', []],
      temporaryOfficeEmail: ['', []],
      isExternalReferral: [false, []],
      temporaryOfficeAddress: ['', []],
      manualName: [this.draft?.profManualFirstName + ' ' + this.draft?.profManualLastName || ' ', []],
      manualFirstName: [this.draft?.profManualLastName || '', []],
      manualLastName: [this.draft?.profManualLastName || '', []],
      isAdult: [true, []],
      googleOfficeName: [],
      googleOfficeStreetAddress: [],
      googleOfficePhone: []
    });

    this.manualDentalProfessionalFirstName = this.draft?.profManualFirstName;
    this.manualDentalProfessionalLastName = this.draft?.profManualLastName;


    this.getListOfDentalProfessionalUnderAnOffice();
    this.getListOfDentalSpecializations();

    this.formGroup.patchValue({portalAccount: AuthenticationServiceKeycloak._currentUserAccount?.accountId});

    this.procedureMasterRecordService.getProcedureJSON()
      .subscribe(res => {
        this.procedureList = res;
        this.procedureList = this.procedureList.map(value => value.name);
      });

    if (this.isDraft) {
      this.showAdultTeeth = this.draft.isAdult;
      this.setFiles();
      this.formGroup.patchValue({
        affectedToothRange: this.affectedTeeth,
        isAdult: this.draft.isAdult,
      });
      this.setFrom();
      this.setDob();
    }

    this.getListOfInsuranceCompanies();
  }

  //emitting Form on next clicked
  previewReferralRequest() {
    this.getFromName();

    if (this.formGroup.getRawValue().affectedToothRange == '' || this.formGroup.getRawValue().affectedToothRange == null || this.formGroup.getRawValue().affectedToothRange == undefined) {
      this.showAffectedToothModal();
      return;
    }

    this.emmitNextClicked.emit({
      officeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
      formGroup: this.formGroup,
      files: this.fileDocs,
      temporaryOffice: this.temporaryOffice,
      bwFileIdList: this.bwFileIdList,
    });
  }

  setFiles() {
    if (this.draftFiles) {
      if (this.draftFiles.length > 0) {
        this.xrayUrl = this.xrayUrl.concat(this.draftFiles);
        this.fileDocs = this.fileDocs.concat(this.draftFiles);
        this.bwFileIdList = this.draftFiles.map(value => value.fileId);
      }

      this.formGroup.patchValue({
        uploadedFiles: JSON.stringify(this.draftFiles.map(value => value.fileId))
      });
    }
  }

  setDob() {
    if (this.draft.dateOfBirth) {
      const currentDD = moment(moment(this.draft.dateOfBirth).format('MM-DD-YYYY'));
      this.model = {
        'year': currentDD.year(),
        'month': currentDD.month() + 1,
        day: currentDD.date()
      }
      this.formGroup.patchValue({dateOfBirth: this.model});
    }
  }

  setFrom() {
    this.formGroup.patchValue({
      referralFrom: !isNaN(Number(Number(this.draft.referredByProfessionalId) ? this.draft.referredByProfessionalId : null))
    })
  }

  getFromName() {
    const id = this.formGroup?.get('referralFrom')?.value;
    if (id && this.professionalList?.length > 0) {
      const index = this.professionalList?.findIndex(value => value?.dentalProfessionalPojo?.id == id);
      const doc = this.professionalList[index]?.dentalProfessionalPojo?.name || ''
      this.formGroup.patchValue({referredBy: doc ? 'Dr. ' + doc : ''})
    }
  }

  getProcedures(procedureTypes) {
    return !this.isDraft || procedureTypes.length == 0 ? '' :
      procedureTypes.split(", ").map(type => {
        return {
          display: type,
          value: type,
        }
      })
  }

  onFileChange(event) {
    let f = event.target.files[0];
    if (f.size > 1024 * 1024 * 10) {
      this.showErrorMessage('File size must not be more than 10MB');
      return;
    }

    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif", "application/pdf", "application/dicom"];
    const isValidExtension = validExtensions.indexOf(f.type) > -1;

    if (!isValidExtension) {
      this.showErrorMessage('Invalid File Type. Select a PDF or an image');
      return;
    }

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {

      this.xrayUrl.push(f);
      this.fileDoc.name = f.name;
      reader.readAsDataURL(f);
      reader.onload = (_event) => {
        this.uploadXrayFiles(reader.result, f.type, f.name)
      }
    }
  }

  onFileDelete(index) {
    this.bwFileController.deleteSingleFile({id: this.bwFileIdList[index]}).subscribe(res => {
      this.xrayUrl.splice(index, 1);
      this.bwFileIdList.splice(index, 1);
      this.fileDeleted.emit(this.fileDocs[index]);
      this.fileDocs.splice(index, 1);
    });
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  addSuggestedProcedure(procedure) {
    const value: Array<any> = this.formGroup.get('procedureType').value ? this.formGroup.get('procedureType').value : [];
    if (value.findIndex(x => x.value === procedure) >= 0)
      return;

    const populate: Array<any> = value ? value : [];
    this.formGroup.patchValue({
      procedureType: populate.concat([{display: procedure, value: procedure}]),
    });
  }

  selectReceivingDentalOffice() {
    this.formGroup.get('externalReferralOfficeEmail').enable();
    this.formGroup.get('externalReferralOfficePhone').enable();

    let bsModalRef = this.modalService.show(SpecialistOfficeModalComponent, {
      initialState: {
        officeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
        portalAccountId: AuthenticationServiceKeycloak._currentUserAccount?.accountId,
        specializationId: this.formGroup.get('specializationId').value,
        selectedInsuranceCode: this.formGroup.get('patientInsurance').value
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-xl modal-md-full modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.onDentalProfPojoEmitter.subscribe(v => {

      this.formGroup.patchValue({
        isExternalReferral: false,
        externalReferralOfficeEmail: '',
        externalReferralOfficePhone: ''
      });
      this.formGroup.patchValue({referralTo: !v.temporaryOfficeId ? v.dentalProfName : v.temporaryOfficeName + ' [Available Dr.]'});
      if (v.googleOfficeName) {
        this.formGroup.patchValue({referralTo: v.googleOfficeName + ' [Available Dr.]', isExternalReferral: true});
      }
      if (!v.temporaryOfficeId && v.dentalOfficeId) {
        if (v.dentalProfessionalId) {
          this.formGroup.get('referralToProfValue').setValidators([Validators.required]);
        }
        this.formGroup.get('referralToOfficeValue').setValidators([Validators.required]);
        this.formGroup.patchValue({referralToProfValue: v.dentalProfessionalId});
        this.formGroup.patchValue({referralToOfficeValue: v.dentalOfficeId});
        this.formGroup.patchValue({referralToOfficeEmail: v.dentalOfficeEmail});
        this.formGroup.patchValue({referralToOfficeName: v.dentalOfficeName});
        this.formGroup.patchValue({referralToOfficeAddress: v.dentalOfficeAddress});
        this.formGroup.patchValue({temporaryOfficeId: null});
        this.formGroup.patchValue({temporaryOfficeName: null});
        this.formGroup.patchValue({temporaryOfficeEmail: null});
        this.formGroup.patchValue({temporaryOfficeAddress: null});
        this.formGroup.patchValue({googleOfficeName: null});
        this.formGroup.patchValue({googleOfficeStreetAddress: null});
        this.formGroup.patchValue({googleOfficePhone: null});

        /** Loading dental office services */
        this.getDentalOfficeServices(v.dentalOfficeId);

        /** Loading the reason for referral drop down list */
        this.getListOfPossibleDentalServices(v.specializationCodes);

      } else if (v.temporaryOfficeId) {
        this.formGroup.patchValue({temporaryOfficeId: v.temporaryOfficeId});
        this.formGroup.patchValue({temporaryOfficeName: v.temporaryOfficeName});
        this.formGroup.patchValue({temporaryOfficeEmail: v.temporaryOfficeEmail});
        this.formGroup.patchValue({temporaryOfficeAddress: v.temporaryOfficeAddress});
        this.formGroup.patchValue({referralToProfValue: null});
        this.formGroup.patchValue({referralToOfficeValue: null});
        this.formGroup.patchValue({referralToOfficeEmail: null});
        this.formGroup.patchValue({referralToOfficeName: null});
        this.formGroup.patchValue({referralToOfficeAddress: null});
        this.formGroup.get('referralToProfValue').setValidators(null);
        this.formGroup.get('referralToOfficeValue').setValidators(null);
        this.formGroup.patchValue({googleOfficeName: null});
        this.formGroup.patchValue({googleOfficeStreetAddress: null});
        this.formGroup.patchValue({googleOfficePhone: null});

      } else if (v.googleOfficeName) {
        this.formGroup.patchValue({googleOfficeName: v.googleOfficeName});
        this.formGroup.patchValue({googleOfficeStreetAddress: v.googleOfficeAddress});
        this.formGroup.patchValue({googleOfficePhone: v.googleOfficePhoneNumber});

        this.dentalReferralControllerService.searchTemporaryDentalOffice({officeName: v.googleOfficeName})
          .subscribe(res => {
            this.formGroup.patchValue({
              externalReferralOfficeEmail: res.email,
              externalReferralOfficePhone: res.phoneNumber
            });
            this.formGroup.patchValue({temporaryOfficeId: res.id});
            this.formGroup.patchValue({temporaryOfficeName: res.name});
            this.formGroup.patchValue({temporaryOfficeEmail: res.email});
            this.formGroup.patchValue({temporaryOfficeAddress: res.addressDetails});

            this.formGroup.get('externalReferralOfficeEmail').disable();
            if (res.phoneNumber) {
              this.formGroup.get('externalReferralOfficePhone').disable();
            }
          });

        this.formGroup.patchValue({referralToProfValue: null});
        this.formGroup.patchValue({referralToOfficeValue: null});
        this.formGroup.patchValue({referralToOfficeEmail: null});
        this.formGroup.patchValue({referralToOfficeName: null});
        this.formGroup.patchValue({referralToOfficeAddress: null});
        this.formGroup.get('referralToProfValue').setValidators(null);
        this.formGroup.get('referralToOfficeValue').setValidators(null);
        this.formGroup.patchValue({temporaryOfficeId: null});
        this.formGroup.patchValue({temporaryOfficeName: null});
        this.formGroup.patchValue({temporaryOfficeEmail: null});
        this.formGroup.patchValue({temporaryOfficeAddress: null});
      }
    });
  }

  getListOfDentalProfessionalUnderAnOffice() {
    this.dentalOfficeProfessional.getAllDentalProfessional({filter: {dentalOfficeId: this.user[0].otherData.id}}).subscribe(res => {
      this.professionalList = res.results;
    });
  }

  getDentalOfficeServices(dentalOfficeId) {
    this.dentalServicesTiles = [
      "Consultation",
      "Treatment",
    ];
    this.loadingDentalServices = true;
    this.dentalOfficeControllerService.getProvidedServices({officeId: dentalOfficeId}).subscribe(res => {
      res.forEach((value, index, array) => {
        if (array.length > 0) {
          this.dentalServicesTiles.push(value.name);
        }
      });

      this.loadingDentalServices = false;
    }, error => {
      this.loadingDentalServices = false;
    })
  }

  getListOfPossibleDentalServices(specializationCodes: Array<SpecializationPojo>) {
    const customProcedureList = specializationCodes.map(function (i) {
      return i.code;
    });
    this.dentalReferralControllerService.searchReferralServices({specializationCode: customProcedureList}).subscribe(res => {
      this.dentalServicesOptions = res.map(function (i) {
        return i.name;
      });
    });
  }

  getListOfDentalSpecializations() {
    this.dentalOfficeProfessional.getAllDentalProfessionalSpecializations().subscribe(res => {
      this.dentalSpecializations = res;
    })
  }

  showAddDoctorModal() {
    let bsModalRef = this.modalService.show(AddReferralProfModalComponent, {
      initialState: {},
      keyboard: false,
      backdrop: true,
      class: 'col-lg-4 modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.emitManualProfDetails.subscribe(v => {
      this.formGroup.get('referralFrom').setValidators(null);
      this.formGroup.get('referralFrom').setErrors(null);
      this.formGroup.patchValue({referralFrom: ''});

      this.formGroup.get('referralToProfValue').setValidators(null);
      this.formGroup.get('referralToProfValue').setErrors(null);
      this.formGroup.patchValue({referralToProfValue: ''});
      bsModalRef.hide();

      this.manualDentalProfessionalFirstName = v.getRawValue().profFirstName;
      this.manualDentalProfessionalLastName = v.getRawValue().profLastName;
      this.formGroup.patchValue({'manualName': this.manualDentalProfessionalFirstName + ' ' + this.manualDentalProfessionalLastName})
      this.formGroup.patchValue({'manualLastName': this.manualDentalProfessionalLastName})
      this.formGroup.patchValue({'manualFirstName': this.manualDentalProfessionalFirstName})
    });
  }

  getListOfInsuranceCompanies(insuranceCompanyCodes?: any) {
    this.insuranceCompanyController.getAllInsuranceCompanies({insuranceCompanyCodes: insuranceCompanyCodes}).subscribe(res => {
      this.insuranceCompanyOptions = res;
    });
  }

  getAge(dob: Date): void {
    // Safety check: if dob is falsy, clear fullAge and return
    if (!dob) {
      this.fullAge = '';
      return;
    }

    // We’ll compare against “today”
    const today = new Date();
    let yearNow = today.getFullYear();
    let monthNow = today.getMonth() + 1;  // JS months are 0-based
    let dateNow = today.getDate();

    // Extract year, month, date from the birthdate
    const yearDob = dob.getFullYear();
    const monthDob = dob.getMonth() + 1;
    const dateDob = dob.getDate();

    // Compute preliminary differences
    let yearAge = yearNow - yearDob;
    let monthAge = monthNow - monthDob;
    let dateAge = dateNow - dateDob;

    // If month difference went negative, adjust year down by 1, and fix months
    if (monthAge < 0) {
      yearAge--;
      monthAge += 12;  // “wrap” the negative month
    }

    // If day difference went negative, adjust month down by 1
    // For simplicity, we use "31" as the fallback for days
    if (dateAge < 0) {
      monthAge--;
      dateAge += 31;  // shift “day age” by 31
    }

    // If month went negative again after adjusting days, fix that
    if (monthAge < 0) {
      yearAge--;
      monthAge = 11;  // reset to last month in a year
    }

    // Build the age string with correct singular/plural terms
    const yearsStr  = yearAge  > 1 ? ' years'  : ' year';
    const monthsStr = monthAge > 1 ? ' months' : ' month';
    const daysStr   = dateAge  > 1 ? ' days'   : ' day';

    let ageStringParts: string[] = [];
    if (yearAge > 0) {
      ageStringParts.push(`${yearAge}${yearsStr}`);
    }
    if (monthAge > 0) {
      ageStringParts.push(`${monthAge}${monthsStr}`);
    }
    if (dateAge > 0) {
      ageStringParts.push(`${dateAge}${daysStr}`);
    }

    // Join up the nonzero parts (“2 years, 3 months, 10 days”)
    // If all parts are zero, this becomes an empty string
    this.fullAge = ageStringParts.join(', ');
  }


  adultTeethToggle() {
    this.showAdultTeeth = !this.showAdultTeeth;
    // this.formGroup.patchValue({affectedToothRange: ''});
    this.formGroup.patchValue({isAdult: this.showAdultTeeth});
  }

  uploadXrayFiles(base64Data, contentType, filename) {
    const uploadFileDto: UploadFileRequestParams = {
      uploadFileDto: {
        file: base64Data,
        contentType: contentType,
        fileName: filename
      }
    }

    this.bwFileController.uploadFile(uploadFileDto, "events", true)
      .subscribe(resp => {
        if (resp.type === HttpEventType.Response) {
          this.progress = 100;
        }
        if (resp.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * resp.loaded / resp.total);
        }
        // @ts-ignore
        const fileId = resp?.body?.fileId
        this.fileDoc.fileId = fileId;

        this.bwFileIdList.push(fileId);
        this.fileDocs.push({contentType: contentType, fileId: fileId, name: filename});
        this.formGroup.patchValue({uploadedFiles: JSON.stringify(this.bwFileIdList)})
      });
  }

  toothArray(): Array<string> {
    return this.affectedTeeth ? this.affectedTeeth.split(", ") : [];
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD').subtract(6, 'months');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  format(date: any) {
    if (!date) return '';
    return moment().set({'year': date.year, 'month': date.month, 'day': date.day}).format('MM-DD-YYYY');
  }

  getFileURL(file, id) {
    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
    const isValidExtension = validExtensions.indexOf(file.type) > -1;

    if (isValidExtension || file.name.toString().endsWith('png' || 'jpg')) {
      return environment.siteUrl + "/files/" + id;
    }

    return "/assets/img/image.png";
  }

  showAffectedToothModal() {
    let bsModalRef = this.modalService.show(OptionSelectResponseComponent, {
      initialState: {
        message: 'Are you sure you want to continue without selecting a tooth ?'
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-sm modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.yesClicked.subscribe(v => {
      bsModalRef.hide();
      this.emmitNextClicked.emit({
        officeId: AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id,
        formGroup: this.formGroup,
        files: this.fileDocs,
        temporaryOffice: this.temporaryOffice,
        bwFileIdList: this.bwFileIdList
      });
    });
  }

  updateTourRideStatus(tourRide: TourRideStatusConstantEnum) {
    this.userControllerService.updateTourRide({
      portalUserId: this.userAccount.id,
      tourRide: tourRide
    }).subscribe(res => {
      this.userAccount.tourRideStatusConstant = tourRide;
    });
  }

  removeTooth(tooth: string) {
    console.log(tooth);
    this.toothDeselected.emit(tooth);
  }

  ngAfterViewInit() {
    // Wait for the DOM to be fully rendered
    setTimeout(() => {
      this.modifyDatepickerHeader();
      console.log('Datepicker header modified');
    }, 0);
  }

  modifyDatepickerHeader() {
    const datepickerHead = this.elRef.nativeElement.querySelector('.bs-datepicker-head');
    if (datepickerHead) {
      const yearSelect = datepickerHead.querySelector('.bs-datepicker-navigation-view .current');
      const monthSelect = datepickerHead.querySelector('.bs-datepicker-month');
      if (yearSelect && monthSelect) {
        this.renderer.insertBefore(datepickerHead, yearSelect, monthSelect);
      }
    }
  }
}
