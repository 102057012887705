<div class="container" style="margin-top: 5rem;">
<div class="row">

    <div class="col-md-6 py-5">
        <h1 class="text-dark">
          Proven to deliver ROI from Day 1
        </h1>
        <p>Simple, Affordable, and Automated dental referral system.</p>
        <p>DentalDoor delivers ROI with your very first referral. This is why DentalDoor is known as "<i
                class="text-primary strong-font"><strong>pays for itself from day one</strong></i>"</p>
        <p>Our pricing model is designed to deliver ROI as quickly as with your very first referral. Now, that's Value! With our no-surprise, predictable pricing model, you can choose a monthly or annual plan that works for your office.</p>
        <p>Sign up, contact us, or schedule a demo to see how DentalDoor helps dental offices and specialty offices like
            yours
            <span class="fw-bold fst-italic text-primary strong-font">increase revenue, increase growth, </span> and <span
                class="fw-bold fst-italic text-primary strong-font">expand your market reach</span> with a real time, streamlined, and efficient
            dental
            referral process.
        </p>
    </div>
    <div class="col-md-6 p-md-5">

        <form class="card p-5 shadow rounded-1 border-0 request-demo-back" [formGroup]="this.formGroup">
          <div *ngIf="showErrorMessageTrigger">
            <div class="alert-danger alert border m-0 mb-3 rounded-2 p-3 alert-dismissible fade show" role="alert"
                 [ngClass]="{'alert-danger': isError, 'alert-success': !isError}">
              <p class="m-0 p-0 d-flex align-items-start">
                      <span>
                        <iconify-icon class="iconify me-2" icon="ep:info-filled"></iconify-icon>
                        {{ getErrorMessage() | removeUnderscores }}
                      </span>
                <iconify-icon class="iconify fs-4 ms-auto pointer" icon="mdi:close" data-bs-dismiss="alert">
                </iconify-icon>
              </p>
            </div>
          </div>
            <h4 class="mb-4 text-light">{{defaultMsg}}</h4>
          <div class="col-12 mb-4">
            <div class="row">
              <div class="col-6">
                <div class="form">
                  <label for="contactPersonFirstName" class="form-label text-light required">First Name</label>
                  <input type="text" class="form-control form-control-lg" id="contactPersonFirstName" placeholder="Enter First Name" formControlName="contactPersonFirstName">
                  <app-field-error *ngIf="
                          (formGroup.get('contactPersonFirstName')?.errors
                            ?.required &&
                            formGroup.get('contactPersonFirstName')?.touched) ||
                          (formGroup.get('contactPersonFirstName')?.errors
                            ?.required &&
                            errorSubmission)" [message]="'First name cannot be blank.'">
                  </app-field-error>
                </div>
              </div>
              <div class="col-6">
                <div class="form">
                  <label for="contactPersonLastName" class="form-label text-light required">Last Name</label>
                  <input type="text" class="form-control form-control-lg" id="contactPersonLastName" placeholder="Enter Last Name" formControlName="contactPersonLastName">
                  <app-field-error *ngIf="
                          (formGroup.get('contactPersonLastName')?.errors
                            ?.required &&
                            formGroup.get('contactPersonLastName')?.touched) ||
                          (formGroup.get('contactPersonLastName')?.errors
                            ?.required &&
                            errorSubmission)" [message]="'Last name cannot be blank.'">
                  </app-field-error>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-4">
            <div class="mb-3">
              <label for="role" class="form-label text-light required">Role</label>
              <select id="role" class="form-select" formControlName="role">
                <option value="" selected>Select Role...</option>
                <option value="Office Manager"> Office Manager </option>
                <option value="Receptionist"> Receptionist </option>
                <option value="Dentist"> Dentist </option>
                <option value="Specialist"> Specialist </option>
                <option value="Other"> Other </option>
              </select>
              </div>
          </div>
          <div class="col-12 mb-4">
            <div class="form">

              <label for="officeName" class="form-label text-light required">Office Name</label>
              <input type="text" class="form-control form-control-lg" id="officeName" placeholder="Office Name" formControlName="officeName">
              <app-field-error *ngIf="
                          (formGroup.get('officeName')?.errors
                            ?.required &&
                            formGroup.get('officeName')?.touched) ||
                          (formGroup.get('officeName')?.errors
                            ?.required &&
                            errorSubmission)" [message]="'Office name cannot be blank.'">
              </app-field-error>
              <app-field-error *ngIf="(formGroup.get('officeName')?.errors?.maxlength &&
                        formGroup.get('officeName')?.touched) ||
                      (formGroup.get('officeName')?.errors?.maxlength &&
                        errorSubmission)" [message]="'Office name cannot be more than fifty(50) characters.'">
              </app-field-error>
              <app-field-error *ngIf="(formGroup.get('officeName')?.errors?.minlength &&
                        formGroup.get('officeName')?.touched) ||
                      (formGroup.get('officeName')?.errors?.minlength &&
                        errorSubmission)" [message]="'Office name cannot be less than two(2) characters.'"></app-field-error>
            </div>
          </div>
            <div class="row g-4">
                <div class="col-12">
                    <div class="form">
                        <label for="floatingInputGrid" class="form-label text-light required">Email address</label>
                        <input type="text" class="form-control form-control-lg" id="floatingInputGrid" placeholder="name@example.com"
                           formControlName="email">
                      <app-field-error *ngIf="
                          (formGroup.get('email')?.errors
                            ?.required &&
                            formGroup.get('email')?.touched) ||
                          (formGroup.get('email')?.errors
                            ?.required &&
                            errorSubmission)" [message]="'Email cannot be blank.'">
                      </app-field-error>
                      <app-field-error *ngIf="(formGroup.get('email')?.errors?.maxlength &&
                        formGroup.get('email')?.touched) ||
                      (formGroup.get('email')?.errors?.maxlength &&
                        errorSubmission)" [message]="'Email cannot be more than fifty(50) characters.'">
                      </app-field-error>
                      <app-field-error *ngIf="(formGroup.get('email')?.errors?.minlength &&
                        formGroup.get('email')?.touched) ||
                      (formGroup.get('email')?.errors?.minlength &&
                        errorSubmission)" [message]="'Email cannot be more than two(2) characters.'"></app-field-error>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form">
                        <label for="phoneNumber" class="form-label text-light required">Phone Number</label>
                      <ngx-intl-tel-input [cssClass]="'form-control form-control-lg'"
                                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [id]="'phoneNumber'"
                                          [maxLength]="'20'" [phoneValidation]="true" [preferredCountries]="preferredCountries"
                                          [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name]" [searchCountryFlag]="true" [selectedCountryISO]="countryISO"
                                          [selectFirstCountry]="true" [numberFormat]="PhoneNumberFormat.National"
                                          formControlName="phoneNumber" id="phoneNumber"></ngx-intl-tel-input>
                        <app-field-error *ngIf="
                            (formGroup.get('phoneNumber')?.errors?.required &&
                              formGroup.get('phoneNumber')?.touched) ||
                            (formGroup.get('phoneNumber')?.errors?.required && errorSubmission)"
                                         [message]="'Phone number cannot be blank.'"></app-field-error>
                        <app-field-error class="mt-1" [message]="'Phone number is invalid.'" *ngIf="
                            formGroup.get('phoneNumber')!.errors?.validatePhoneNumber"></app-field-error>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form">
                        <label for="floatingTextarea" class="form-label text-light ">Note</label>
                      <textarea class="form-control form-control-lg" formControlName="note" id="floatingTextarea"
                                rows="2" maxlength="500" placeholder="Notes [max 500]"></textarea>
                      <app-field-error *ngIf="
                                formGroup.get('note').errors
                                  ?.maxLength &&
                                formGroup.get('note')?.touched
                              " [message]="'Enter notes if available.'"></app-field-error>
                    </div>
                </div>

                <div class="col-12">
                    <button type="button"  class="btn btn-large fs-6 btn-bd-primary float-end mt-3" (click)="scheduleCall()" [disabled]="this.formGroup.untouched">
                      <iconify-icon
                        *ngIf="loading" icon="eos-icons:bubble-loading" style="color: #ffffff">
                      </iconify-icon>
                      Send Request</button>
                </div>

            </div>
        </form>
    </div>

</div>
</div>
