import { Component, OnInit } from '@angular/core';
import {SocialService} from "../../../shared/subject/SocialService";
import {Router} from "@angular/router";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import { title } from 'process';
import { CommissionLogPojo } from 'dd-core-api-sdk';
import { MoreInfoModalComponent } from 'src/app/shared/more-info-modal/more-info-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LearnMoreModalComponent } from 'src/app/learn-more-modal/learn-more-modal.component';


@Component({
  selector: 'app-general-home-page',
  templateUrl: './general-home-page.component.html',
  styleUrls: ['./general-home-page.component.css']
})
export class GeneralHomePageComponent implements OnInit{

  titleSwitch: string = 'Say farewell to paper referrals and welcome increased revenue with DentalDoor!';


  constructor(public socialService: SocialService,
    private router: Router, private modalService: BsModalService) { }


  nextRoute(){
    window.scroll(0,0);
    this.router.navigate(['/signup']);
  }

  ngOnInit(){
  
    setInterval(() => {
      this.titleSwitch = this.titleSwitch === "Say farewell to paper referrals and welcome increased revenue with DentalDoor!" ? " Revolutionize the way you send and receive patients with DentalDoor's dRefer." : "Say farewell to paper referrals and welcome increased revenue with DentalDoor!";
    }, 10000);
  }

  learnMore() {
    let bsModalRef = this.modalService.show(LearnMoreModalComponent, {
      initialState: {
        infoTitle: 'Learn More',
      },
      keyboard: false,
      backdrop: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });

    bsModalRef?.content?.dismissClicked.subscribe(v => {
      bsModalRef.hide();
    });
    bsModalRef?.content?.closeClicked.subscribe(v => {
      bsModalRef.hide();
    });
  }

  requestDemo() {
    this.router.navigate(['/pricing'], { queryParams: { type: 'DEMO' } });
  }
}

