/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Appointment } from './appointment';
import { InsuranceCompany } from './insurance-company';
import { ReferralRequest } from './referral-request';
import { DentalOffice } from './dental-office';


export interface PatientSearchFilter { 
    email?: string;
    id?: number;
    code?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    dentalOffice?: DentalOffice;
    gender?: PatientSearchFilter.GenderEnum;
    insuranceCompany?: InsuranceCompany;
    fhirProviderId?: string;
    appointments?: Array<Appointment>;
    referralRequests?: Array<ReferralRequest>;
    limit?: number;
    displayName?: string;
    offset?: number;
}
export namespace PatientSearchFilter {
    export type GenderEnum = 'MALE' | 'FEMALE' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
}


