/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DentalProfessionalAwardSearchFilter { 
    isDentalProfessionalCerts?: boolean;
    isDentalProfessionalAwards?: boolean;
    awardInstitutionId?: number;
    dentalProfessionalId?: number;
    createdOnOrAfter?: string;
    createdBefore?: string;
    id?: number;
    title?: string;
    verified?: boolean;
    yearIssued?: number;
    certificateNumber?: string;
    dateOfVerification?: string;
    type?: DentalProfessionalAwardSearchFilter.TypeEnum;
    limit?: number;
    offset?: number;
}
export namespace DentalProfessionalAwardSearchFilter {
    export type TypeEnum = 'HONORARY' | 'CERTIFICATION' | 'PUBLICATION';
    export const TypeEnum = {
        Honorary: 'HONORARY' as TypeEnum,
        Certification: 'CERTIFICATION' as TypeEnum,
        Publication: 'PUBLICATION' as TypeEnum
    };
}


