/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentInvoicePojo { 
    id?: number;
    amount?: number;
    dateCreated?: string;
    paymentStatus?: PaymentInvoicePojo.PaymentStatusEnum;
    customerName?: string;
    customerPhoneNumber?: string;
    customerEmail?: string;
    invoiceNumber?: string;
    datePaid?: string;
    status?: PaymentInvoicePojo.StatusEnum;
}
export namespace PaymentInvoicePojo {
    export type PaymentStatusEnum = 'PAID' | 'NOT_PAID' | 'PARTIALLY_PAID' | 'TRANSFERED' | 'IN_TRANSIT' | 'FAILED';
    export const PaymentStatusEnum = {
        Paid: 'PAID' as PaymentStatusEnum,
        NotPaid: 'NOT_PAID' as PaymentStatusEnum,
        PartiallyPaid: 'PARTIALLY_PAID' as PaymentStatusEnum,
        Transfered: 'TRANSFERED' as PaymentStatusEnum,
        InTransit: 'IN_TRANSIT' as PaymentStatusEnum,
        Failed: 'FAILED' as PaymentStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


