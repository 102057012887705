<div class="">
  <!-- header section  -->
  <section class="header-bg pt-5">
    <div class="container mt-4">
      <div class="row p-lg-5 px-lg-0 ps-md-0 d-flex justify-content-between">
        <div class="col-lg-6 pe-xl-5">
          <div class="pe-xl-1">
            <h1 class="header-main-title my-5">
              Stop Referral Leakage and expand your market reach!

              <img src="/assets/img/headerLiner.svg" class="img-fluid" alt="" style="
                  position: relative;
                  bottom: 0;
                  left: 0;
                  margin-left: -90px;
                  margin-bottom: -40px;
               "/>
            </h1>
            <p class="app-download-p pe-xl-5 fs-6"> {{titleSwitch}}</p>
          </div>

          <div class="my-5 d-flex flex-column flex-md-row">
            <button routerLink="/login" class="btn btn-primary me-md-3 mb-4 mb-md-0 px-5" type="button">
              Refer a Patient
            </button>
            <button (click)="requestDemo()" class="btn btn-outline-primary px-5" type="button">
              <iconify-icon
                icon="teenyicons:discount-solid"
                class="m-0 p-0"
              ></iconify-icon>
              Request A Demo
            </button>
          </div>
        </div>
        <div
          class="animate__animated animate__pulse col-lg-6 col-md-6 d-flex justify-content-lg-center align-items-center">
          <img src="/assets/img/female-dentist.svg" class="img-fluid header-nurse" alt=""/>
        </div>
      </div>
    </div>
  </section>


  <section class="video my-5">
    <div class="container">
      <div class="row py-5">
        <div class="col-md order-lg-2">
          <div class="px-1 ms-lg-5">
            <h1 class="h1">What is DentalDoor?</h1>
            <p class="app-download-p">DentalDoor is an e-referral platform that streamlines the process of referring
              patients for dental treatment, increasing the likelihood of them scheduling and attending
              appointments.</p>
            <p class="app-download-p">
              Check out this video to quickly learn about the important features and advantages of DentalDoor. Click
             "Learn more" to get a quick jumpstart on how to navigate the platform. It is simple and easy!
            </p>
            <div class="mb-3 mt-4">
              <button (click)="learnMore()"
                      class="btn py-2 btn-primary d-flex align-items-center justify-content-center">
                <iconify-icon icon="mingcute:youtube-fill" style="color: white;" width="30"></iconify-icon>
                <span class="px-2"> Learn more</span></button>
            </div>
          </div>
        </div>
        <div class="col-auto order-lg-1 order-2">
          <div class="shadow iframe-cover rounded-4">
            <div>
              <iframe class="rounded-4 iframe"
                      src="https://player.vimeo.com/video/781435193?h=d604b60730&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                      style="position:absolute;top:0;left:0;width:100%;height:100%;"
                      title="What is DentalDoor?"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="hipaa-bg">
    <div class="container py-lg-0 py-4">
      <div class="row py-lg-4 hipaa-flex d-flex justify-content-xl-end align-items-center">
        <div class="col-1 d-none d-xl-block"></div>

        <div class="col-lg-3 d-none d-lg-block">
          <img src="/assets/img/hipaa.png" class="img-fluid" alt=""/>
        </div>
        <div class="col-md-12 col-lg-6 py-3">
          <h1 class="header-main-title my-lg-3 my-3">
            HIPAA Compliant Dental Referral System.
          </h1>

          <p class="hipaa-subtext pe-md-5 py-3">
            The DentalDoor platform incorporates administrative, physical, and technical safeguard of standards set by
            the Health Insurance Portability and Accountability Act (HIPAA) to protect the confidentiality, integrity,
            and availability of all data following
            the Security Rule.
          </p>
        </div>
        <div class="col-1 d-none d-xl-block"></div>
      </div>
    </div>
  </section>


  <section class="testimonial-bg">
    <div class="container">
      <div class="row d-flex py-5 justify-content-center align-items-center">
        <div class="col-md-6">
          <img src="/assets/img/dsoSection.svg" class="img-fluid" alt=""/>
        </div>
        <div class="col-md-6 mt-5 mt-md-0">
          <h1 class="h1 text-end">Why DentalDoor?</h1>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button text-dark" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Referral Leakage in the US
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    As many as <strong class="text-warning digit-p">50%</strong> of patient referrals are never
                    scheduled, seen, or completed. For the most part, receiving specialist do not even know about the
                    referred patient because
                    they never received the referral form.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Revenue lost due to referral leakage in the US
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    How many times has a patient referred to you not made an appointment? How much have you contributed
                    to the <strong class="text-warning digit-p">$2.5bn</strong> lost revenue? dRefer not only helps you
                    know the answers,
                    but also helps your office reduce that number to <strong class="text-warning digit-p">0</strong>.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Providers dissatisfied due to lack of timely updates
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    dRefer has incorporated easy to use tools to keep referring provider in the loop and follow the
                    progress of referred patient journey through the referral process from sending to completion.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed text-dark" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  Money you will spend for referral pads
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p>
                    Depending on location, referral pads average anywhere from
                    <strong class="text-warning digit-p">$700</strong> to <strong
                    class="text-warning digit-p">$2000</strong> per year to a specialist office. Having a cloud-based
                    referral system like dRefer eliminates that cost.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mt-5 mt-md-0" id="about">
    <div class="container py-3 py-md-5">
      <div class="row py-md-5 py-3">
        <div class="text-center d-flex justify-content-center">
          <div class="col-md-6 col-12">
            <h2 class="chart-h1">
              <i>d</i>Refer platform is a
              <strong class="">Win Win Win </strong>Solution!
            </h2>
            <p class="discover-p">
              We connect you with the best dentists and present available schedules with their comfirmed appointment
              dates so that booking is seamless and easy.
            </p>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row d-flex justify-content-center flex-wrap">
            <div class="col-md-10 col-12">
              <div class="d-flex flex-column flex-wrap flex-md-row justify-content-center curved-line-bg my-5">
                <div class="col-lg-4 col-12 d-flex my-5 my-md-0 justify-content-center align-items-center">
                  <div class="text-center">
                    <img src="/assets/img/loction-time.svg" class="img-fluid" alt=""/>

                    <div class="d-flex justify-content-center mt-4">
                      <div class="col-md-10 col-10">
                        <h5>PATIENT</h5>
                        <p class="discover-p my-3">
                          With dRefer, referral processes are efficient, eliminating referral leakage, lost, forgotten
                          or delayed referrals. This ensures patient gets the care they need. This is a WIN for the
                          patient - thanks to dRefer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-12 my-5 my-md-0 d-flex justify-content-center align-items-center">
                  <div class="text-center mt-4">
                    <img src="/assets/img/multilingual.svg" class="img-fluid" alt=""/>

                    <div class="d-flex justify-content-center mt-4">
                      <div class="col-md-10 col-10">
                        <h5>REFERRING DENTAL OFFICE</h5>
                        <p class="discover-p my-3">
                          Referring office can easily collaborate with specialist in real time, follow up with patient
                          when treatment is complete and schedule a post referral appointment if needed. Referring
                          dental office is constantly on the loop of what is going on.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-12 my-md-0 my-5 d-flex justify-content-center align-items-center">
                  <div class="text-center">
                    <img src="/assets/img/reviewdo.svg" class="img-fluid" alt=""/>

                    <div class="d-flex justify-content-center mt-4">
                      <div class="col-md-10 col-10">
                        <h5>RECEIVING SPECIALIST OFFICE</h5>
                        <div class="d-flex justify-content-center my-3">
                          <p class="col-md-9 discover-p">
                            Referral processes are efficient and specialist office is able to track treatment progress
                            right from the platform.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="doffice-bg py-3">
    <div class="container py-5">
      <div class="row py-5">
        <div class="col-md-6 d-flex justify-content-center align-items-center">
          <img src="/assets/img/doffice.png" class="img-fluid" width="450" height="auto" alt=""/>
        </div>
        <div class="col-md-6">
          <img src="/assets/img/doicon.png" class="img-fluid" alt=""/>

          <h2 class="do-title-text text-light mt-4">Own a Dental Office?</h2>

          <div class="pe-5 my-3">
            <p class="app-download-p text-light fw-light pe-md-5">
              Choosing a specialist has never been so fast! You can filter search results by location and specialization
            </p>
          </div>

          <p class="text-light my-4">
            <strong> Sign up </strong>
            <span class="opacity-75 fw-light">today and explore our cool features.</span
            >
          </p>

          <button class="btn btn-outline-light px-5" (click)="nextRoute()">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="">
    <div class="container py-5">
      <div class="row py-5">
        <div class="col-md-6 d-flex justify-content-end">
          <div class="col-md-9">
            <img src="/assets/img/dproficon.png" class="img-fluid" alt=""/>

            <h2 class="dp-title-text mt-4">
              <h2 class="do-title-text mt-4">A Dental Professional?</h2>
            </h2>

            <div class="my-3 mb-5">
              <p class="app-download-p">
                The service allows you to get maximum visibility online and to
                manage appointments and contacts coming from the site, in a
                simple and fast way.
              </p>
            </div>

            <div class="d-flex flex-column flex-md-row">
              <button
                class="btn btn-primary me-md-3 mb-4 px-5"
                type="button"
                (click)="nextRoute()"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-md-6 d-flex mt-5 mt-md-0 justify-content-center align-items-center"
        >
          <img
            src="/assets/img/male-dentist.svg"
            class="img-fluid"
            width="450"
            height="auto"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <section class="testimonial-bg">
    <div class="container">
      <div class="row py-5 d-flex justify-content-center align-items-center">
        <div class="col-md-6 d-flex justify-content-end">
          <div class="col-md-7">
            <div class="testimonial-desc pb-5">
              <p class="text-primary">TESTIMONIALS</p>
              <h2 class="text-primary">What Our Clients Say</h2>
              <p class="app-download-p">
                DentalDoor keeps challenging the status quo, redefining how
                referral process should be.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="col-md-9">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
              data-bs-touch="true"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="icon"></div>
              <div class="carousel-inner testimonial-card">
                <div class="carousel-item active">
                  <div class="d-block w-100 p-4">
                    <div class="d-flex">
                      <div>
                        <iconify-icon
                          icon="icomoon-free:quotes-left"
                          class="fs-2 me-3"
                        ></iconify-icon>
                      </div>
                      <div class="col-md-10">
                        <p class="test-desc">
                          The platform helps you confidently grow and manage your dental referrals with dRefer software.
                          I get to streamline my day-to-day
                          referrals and increase my office's efficiency with flexible features in a single, easy-to-use
                          solution for your general
                          dentists and specialists.
                        </p>

                        <div class="d-flex align-items-center">
                          <img
                            src="/assets/img/testimonial-avatar1.png"
                            width="50"
                            height="auto"
                            class="img-fluid rounded-5 me-3"
                            alt=""
                          />
                          <div>
                            <p class="fw-bold mb-0">Dr. Rose</p>
                            <p class="test-occupation mb-0">
                              Dental Specialist
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="d-block w-100 p-4">
                    <div class="d-flex">
                      <div>
                        <iconify-icon
                          icon="icomoon-free:quotes-left"
                          class="fs-2 me-3"
                        ></iconify-icon>
                      </div>
                      <div class="col-md-10">
                        <p class="test-desc">
                          DentalDoor easily eliminates dental referral leakage for me.
                        </p>

                        <div class="d-flex align-items-center">
                          <img
                            src="/assets/img/testimonial-avatar2.png"
                            width="50"
                            height="auto"
                            class="img-fluid rounded-5 me-3"
                            alt=""
                          />
                          <div>
                            <p class="fw-bold mb-0">Dr. Dramendra</p>
                            <p class="test-occupation mb-0">Dentist</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="d-block w-100 p-4">
                    <div class="d-flex">
                      <div>
                        <iconify-icon
                          icon="icomoon-free:quotes-left"
                          class="fs-2 me-3"
                        ></iconify-icon>
                      </div>
                      <div class="col-md-10">
                        <p class="test-desc">
                          I get to know immediaitely when a patient is referred to me, see reason for the referral and
                          all pertinent information about the
                          patient to schedule and see them, even before they get up from the dental referrer’s chair. –
                          This is nice!
                        </p>

                        <div class="d-flex align-items-center">
                          <img
                            src="/assets/img/testimonial-avatar3.png"
                            width="50"
                            height="auto"
                            class="img-fluid rounded-5 me-3"
                            alt=""
                          />
                          <div>
                            <p class="fw-bold mb-0">Dr. Esther</p>
                            <p class="test-occupation mb-0">Endodontist</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <!-- <section>
    <div class="container-fluid px-0">
      <div class="row">
        <div class="d-none d-md-block">
          <img src="/assets/img/mobileApp.svg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section> -->
</div>
