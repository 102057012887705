/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TagSearchFilter { 
    isValidated?: boolean;
    id?: number;
    code?: string;
    type?: TagSearchFilter.TypeEnum;
    limit?: number;
    name?: string;
    offset?: number;
}
export namespace TagSearchFilter {
    export type TypeEnum = 'PROMOTION' | 'IMAGE' | 'STATE' | 'CITY';
    export const TypeEnum = {
        Promotion: 'PROMOTION' as TypeEnum,
        Image: 'IMAGE' as TypeEnum,
        State: 'STATE' as TypeEnum,
        City: 'CITY' as TypeEnum
    };
}


