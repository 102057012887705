<div class="" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog m-0" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">{{ (tempDentalOffice ? 'Update': 'Create') + ' Temp Dental Office' }}</h4>
        <button
          (click)="close()" class="btn">
          <i class="fa-regular fa-circle-xmark"></i>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="showErrorMessageTrigger">
          <div class="alert-danger alert border m-0 mb-3 rounded-2 p-4 alert-dismissible fade show"
               role="alert">
            <p class="m-0 p-0 d-flex align-items-start">
                <span>
                  <iconify-icon
                    class="iconify me-2"
                    icon="ep:info-filled"
                  ></iconify-icon>
                  {{ getErrorMessage()  }}
                </span>
              <iconify-icon
                class="iconify fs-4 ms-auto pointer"
                icon="mdi:close"
                data-bs-dismiss="alert"
              >
              </iconify-icon>
            </p>
          </div>
        </div>
        <div class="m-3" [formGroup]="form">

          <div class="row">
            <div class="col-lg-6 mb-4">
              <label for="office-email-input" class="form-label">Office Name</label>
              <input
                type="text"
                class="form-control"
                ngx-google-places-autocomplete
                [options]="placesConfig"
                formControlName="name"
                placeholder="Office Name"
                (onAddressChange)="getPlacesAutocomplete($event)"
              />
              <app-field-error
                *ngIf="
                    (form.get('name')?.errors?.required &&
                        form.get('name')?.touched) ||
                      (form.get('name')?.errors?.required &&
                        formError)"
                [message]="'Office Name cannot be blank.'"></app-field-error>
            </div>
            <div class="col-lg-6 mb-4">
              <label for="office-email-input" class="form-label">Enter Email</label>
              <input
                id="office-email-input"
                class="form-control"
                type="text"
                formControlName="email"
                placeholder="eg. yourofficemail@mail.com"
              />
              <app-field-error
                *ngIf="
                    (form.get('email')?.errors?.required &&
                        form.get('email')?.touched) ||
                      (form.get('email')?.errors?.required &&
                        formError)"
                [message]="'Office Email cannot be blank.'"></app-field-error>

              <app-field-error
                *ngIf="(this.form.get('email').errors?.pattern && form.get('email')?.touched)"
                [message]="'Invalid email address.'"></app-field-error>

              <app-field-error
                *ngIf="
                      (form.get('email')?.errors?.maxlength &&
                        form.get('email')?.touched) ||
                      (form.get('email')?.errors?.maxlength &&
                        formError)
                    "
                [message]="'Email cannot be more than fifty(50).'"
              ></app-field-error>
              <app-field-error *ngIf="(this.form.get('email').errors?.notDeliverable &&
                 form.get('email')?.touched) ||(this.form.get('email').errors?.notDeliverable &&
                 formError) " [message]="'Please double-check email address'"></app-field-error>
            </div>
            <div class="col-lg-6 mb-4">
              <label for="office-email-input" class="form-label">Enter Alternate Email (Optional)</label>
              <input
                id="office-alt-email-input"
                class="form-control"
                type="text"
                formControlName="alternateEmail"
                placeholder="eg. alternateEmail@mail.com"
              />
              <app-field-error
                *ngIf="(this.form.get('alternateEmail').errors?.pattern && form.get('alternateEmail')?.touched)"
                [message]="'Invalid email address.'"></app-field-error>

              <app-field-error
                *ngIf="
                      (form.get('alternateEmail')?.errors?.maxlength &&
                        form.get('alternateEmail')?.touched) ||
                      (form.get('alternateEmail')?.errors?.maxlength &&
                        formError)
                    "
                [message]="'Email cannot be more than fifty(50).'"
              ></app-field-error>
              <app-field-error *ngIf="(this.form.get('alternateEmail').errors?.notDeliverable &&
                 form.get('alternateEmail')?.touched) ||(this.form.get('alternateEmail').errors?.notDeliverable &&
                 formError) " [message]="'Please double-check email address'"></app-field-error>
              <app-field-error
                *ngIf="form.errors?.sameEmails"
                [message]="'Email and Alternate Email cannot be the same.'">
              </app-field-error>
            </div>
            <div class="col-lg-6 mb-4">
              <label for="office-email-input" class="form-label">Office Address</label>
              <input
                id="office-address-input"
                class="form-control"
                type="text"
                formControlName="address"
                placeholder="eg. Office Address"
              />
              <app-field-error
                *ngIf="
                    (form.get('address')?.errors?.required &&
                        form.get('address')?.touched) ||
                      (form.get('address')?.errors?.required &&
                        formError)"
                [message]="'Office Address cannot be blank.'"></app-field-error>
            </div>
            <div class="col-lg-6 mb-4">
              <label class="form-label">Phone Number </label>
              <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true" [id]="'phoneNumber'" [maxLength]="'20'"
                                  [phoneValidation]="true"
                                  [preferredCountries]="preferredCountries"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [searchCountryFlag]="true"
                                  [selectedCountryISO]="countryISO" [selectFirstCountry]="true"
                                  formControlName="phoneNumber"></ngx-intl-tel-input>
              <app-field-error
                *ngIf="(form.get('phoneNumber')?.errors?.required && form.get('phoneNumber')?.touched) || (form.get('phoneNumber')?.errors?.required && formError)"
                [message]="'Phone number cannot be blank.'"></app-field-error>
            </div>
            <div class="col-lg-6 mb-4">
              <label class="form-label">Fax Number </label>
              <ngx-intl-tel-input [cssClass]="'form-control'" [enableAutoCountrySelect]="true"
                                  [enablePlaceholder]="true" [id]="'fax'" [maxLength]="'20'"
                                  [phoneValidation]="true"
                                  [preferredCountries]="preferredCountries"
                                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                  [searchCountryFlag]="true"
                                  [selectedCountryISO]="countryISO" [selectFirstCountry]="true"
                                  formControlName="fax"></ngx-intl-tel-input>
              <app-field-error
                *ngIf="(form.get('fax')?.errors?.required && form.get('fax')?.touched) || (form.get('fax')?.errors?.required && formError)"
                [message]="'Fax number cannot be blank.'"></app-field-error>
            </div>

            <div class="col-lg-6 mb-4">
              <label for="specialization-input" class="form-label">Select Specialization (Optional)</label>
              <select id="specialization-input" class="form-control" (change)="updateSelectedSpecialization($event)" multiple>
                <option *ngFor="let spec of allSpecializations" [value]="spec.value">{{ spec.name }}</option>
              </select>
              <small *ngIf="!selectedSpecializations?.length" class="text-muted d-block">Hold down <strong>Ctrl </strong> to select multiple options.</small>

              <!-- Display selected values -->
              <div *ngIf="selectedSpecializations?.length">
                <strong>Selected: </strong> {{ selectedSpecializations.join(', ') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>

        <button *ngIf="!tempDentalOffice" type="button" class="btn btn-primary" (click)="create()">

          <span *ngIf="loading" class="fa fa-spinner fa-spin"></span>
          <span *ngIf="!loading" class="fa fa-plus"></span>
          {{ 'Create' }}
        </button>
        <button *ngIf="tempDentalOffice"  type="button" class="btn btn-primary" (click)="update()">
          <span *ngIf="loading" class="fa fa-spinner fa-spin"></span>
          <span *ngIf="!loading" class="fa fa-pencil-square-o"></span>
          {{ 'Update' }}</button>
      </div>
    </div>
  </div>
</div>
