import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PhoneNumberValidator} from "dd-shared-lib";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {ResendNotificationDto} from "../referral-list.component";
import {DeliverableEmailValidator} from "../../../services/deliverable-email.validator";
import {ReferralSearchPojo} from "dd-core-api-sdk";

@Component({
  selector: 'app-resend-notification-modal',
  templateUrl: './resend-notification-modal.component.html',
  styleUrls: ['./resend-notification-modal.component.css'],
  providers: [DeliverableEmailValidator]
})
export class ResendNotificationModalComponent implements OnInit {


  @Output() onSubmitButtonClick: EventEmitter<ResendNotificationDto> = new EventEmitter();
  @Output() onDismissButtonClick = new EventEmitter();
  @Input() referral: ReferralSearchPojo;

  selectedOption: string = 'PATIENT'; // Default selection

  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;


  resendForm: FormGroup;
  formError = false;
  errMessage: any;
  showErrorMessageTrigger: boolean;


  constructor(private fb: FormBuilder,
              private deliverableEmailValidator: DeliverableEmailValidator) {
  }

  ngOnInit(): void {
    this.resendForm = this.fb.group({
      email: [this.referral.patient.email || '', {
        Validators: [Validators.required, Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$")],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
      phoneNumber: [this.referral.patient.phoneNumber || '', [PhoneNumberValidator()]],
      referralRequestId: [this.referral.referralRequestId || '', [Validators.required]],
    })

  }

  getErrorMessage() {
    return this.errMessage;
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 5000);
  }

  close() {
    this.onDismissButtonClick.emit();
  }

  submit() {
    if (this.resendForm.invalid) {
      this.formError = true;
      return;
    }
    this.formError = false;
    this.onSubmitButtonClick.emit({
      ...this.resendForm.getRawValue(),
      type: this.selectedOption,
      phoneNumber: this.resendForm.get('phoneNumber').value.e164Number
    });
  }

  selectOption(option: string): void {
    this.selectedOption = option;
    if(option === 'PATIENT') {
      this.resendForm.get('email').setValue(this.referral.patient.email);
      this.resendForm.get('phoneNumber').setValue(this.referral.patient.phoneNumber);
    } else if(option === 'DENTAL_OFFICE') {
      this.resendForm.get('email').setValue(this.referral.temporaryDentalOfficePojo.email);
    }
  }

}
