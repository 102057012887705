/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReviewSearchFilter { 
    createdOnOrAfter?: string;
    createdBefore?: string;
    orderBy?: Set<ReviewSearchFilter.OrderByEnum>;
    entityTypeConstant?: ReviewSearchFilter.EntityTypeConstantEnum;
    reviewerId?: number;
    id?: number;
    title?: string;
    email?: string;
    phoneNumber?: string;
    entityId?: number;
    reviewId?: string;
    recordSource?: ReviewSearchFilter.RecordSourceEnum;
    rating?: number;
    reviewDate?: string;
    primaryRating?: number;
    remainAnonymous?: boolean;
    edited?: boolean;
    sourceLink?: string;
    expiryNotificationCount?: number;
    comment?: string;
    entityName?: string;
    limit?: number;
    status?: ReviewSearchFilter.StatusEnum;
    name?: string;
    offset?: number;
}
export namespace ReviewSearchFilter {
    export type OrderByEnum = 'RATING_ASC' | 'RATING_DESC' | 'DATE_CREATED_DESC';
    export const OrderByEnum = {
        RatingAsc: 'RATING_ASC' as OrderByEnum,
        RatingDesc: 'RATING_DESC' as OrderByEnum,
        DateCreatedDesc: 'DATE_CREATED_DESC' as OrderByEnum
    };
    export type EntityTypeConstantEnum = 'DentalOffice' | 'DentalProfessional' | 'Promotion' | 'Review' | 'FlashCardSet' | 'Comment';
    export const EntityTypeConstantEnum = {
        DentalOffice: 'DentalOffice' as EntityTypeConstantEnum,
        DentalProfessional: 'DentalProfessional' as EntityTypeConstantEnum,
        Promotion: 'Promotion' as EntityTypeConstantEnum,
        Review: 'Review' as EntityTypeConstantEnum,
        FlashCardSet: 'FlashCardSet' as EntityTypeConstantEnum,
        Comment: 'Comment' as EntityTypeConstantEnum
    };
    export type RecordSourceEnum = 'DENTAL_DOOR' | 'GOOGLE' | 'FACEBOOK' | 'YELP' | 'BING' | 'YAHOO';
    export const RecordSourceEnum = {
        DentalDoor: 'DENTAL_DOOR' as RecordSourceEnum,
        Google: 'GOOGLE' as RecordSourceEnum,
        Facebook: 'FACEBOOK' as RecordSourceEnum,
        Yelp: 'YELP' as RecordSourceEnum,
        Bing: 'BING' as RecordSourceEnum,
        Yahoo: 'YAHOO' as RecordSourceEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DEACTIVATED';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum,
        Deactivated: 'DEACTIVATED' as StatusEnum
    };
}


