import {Injectable} from "@angular/core";
import {SwPush, SwUpdate} from "@angular/service-worker";

@Injectable({providedIn: 'root'})
export class PwaService {
  promptEvent: any;

  constructor(private swUpdate: SwUpdate, private swPush: SwPush) {

    window.addEventListener('beforeinstallprompt', event => {
      console.log('beforeinstallprompt', event);
        this.promptEvent = event;
    });
    swUpdate.available.subscribe(event => {
      console.log('swUpdate', event);

      swUpdate.activateUpdate().then(() => document.location.reload());
    });
  }
}
