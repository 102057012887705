import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthenticationServiceKeycloak} from "../../../services/AuthenticationServiceKeycloak";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {ConfirmFieldValidator, PasswordValidator, PhoneNumberValidator} from "dd-shared-lib";
import {
  City,
  Country,
  CreateDentalOfficeWithAdminRequestParams,
  DentalOfficeCreationControllerService,
  LocationService,
  QueryResults,
  QueryResultsCity,
  UserRegistrationDto, UserRegistrationService
} from "dd-core-api-sdk";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {AsyncSubject} from "rxjs";
import {Router} from "@angular/router";
import {UniqueIdValidatorService} from "../../../shared/unique-id-validator.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {SocialService} from "../../../shared/subject/SocialService";
import {AccountCreatedSuccessComponent} from "../../account-created-success/account-created-success.component";
import {InvitesModel} from "../../../shared/invites-model";
import {DeliverableEmailValidator} from "../../../services/deliverable-email.validator";

@Component({
  selector: 'app-create-dental-office',
  templateUrl: './create-dental-office.component.html',
  styleUrls: ['./create-dental-office.component.css'],
  providers: [DeliverableEmailValidator]
})
export class CreateDentalOfficeComponent implements OnInit {
  @Input()
  invitesData: InvitesModel;
  @Input()
  affiliateCode: String;
  basicInfoForm: FormGroup;
  addressForm: FormGroup;
  adminForm: FormGroup;
  strongPassword: any;
  displayPassword = false;
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.UnitedStates;
  errorSubmission: boolean;
  submitted: boolean;
  states: any[];
  countries: Country[];
  country: Country;
  cities: City[];
  loadingCites = false;
  loadingStates = false;
  destroy$ = new AsyncSubject<boolean>();
  submitting: boolean;
  selectedSpeciality: any;
  addressExists = true;

  profession: Array<any> = [
    {name: 'Oral Surgery', value: 'Oral and Maxillofacial Surgery'},
    {name: 'Periodontics', value: 'Periodontics'},
    {name: 'Endodontics', value: 'Endodontics'},
    {name: 'Prosthodontist', value: 'Prosthodontist'},
  ];

  profession2: Array<any> = [
    {name: 'Orthodontics', value: 'Orthodontics'},
    {name: 'Oral Pathology', value: 'Oral and Maxillofacial Pathology'},
    {name: 'Pedodontics', value: 'Pedodontics'},
    {name: 'Physician Office', value: 'Physician Office'}
  ];
  general: Array<any> = [
    {name: 'Dental Hygiene School', value: 'Dental Hygiene School'},
    {name: 'Dental School', value: 'Dental School'},
  ]
  isSpecialist: boolean = false;
  isGeneral: boolean = false;
  placesConfig: any = {
    componentRestrictions: {country: ['US'],}
    // ,
    // types: ['hospital', 'dentist', 'doctor','dental clinic', 'pharmacy']
  }
  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  googleCity: string;
  googleState: string;
  googleCountry: string;
  countryId: any;
  reviews: any[] = [];

  errMessage = '';
  successMessage = '';
  showErrorMessageTrigger = false;
  showSuccessMessageTrigger = false;
  intervalStart: boolean;
  interval: number;
  addressSelected: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationServiceKeycloak,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private createDentalOffice: DentalOfficeCreationControllerService,
    private userRegistration: UserRegistrationService,
    private uniqueIdValidatorService: UniqueIdValidatorService,
    private deliverableEmailValidator: DeliverableEmailValidator,
    private modalService: BsModalService,
    private socialService: SocialService,
    private renderer2: Renderer2,
    private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.submitted = true;
    this.basicInfoForm = this.fb.group({
      officeName: [this.invitesData?.officeName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      officeType: new FormArray([], [Validators.required, this.schoolSelected]),
      phoneNumber: [this.invitesData?.phoneNumber || '', [Validators.required, PhoneNumberValidator()]],
      officeAddress: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(150)]],
      email:
        [this.invitesData?.email, {
        Validators: [Validators.required,Validators.maxLength(50), Validators.pattern("^[a-zA-Z0-9.!#$%&amp;'^_`{}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$")],
        asyncValidators: [this.deliverableEmailValidator.emailExistsValidator()],
        updateOn: 'blur'
      }],
    });
    this.addressForm = this.fb.group({
      street: [''],
      state: [''],
      city: [''],
      country: [''],
      zipCode: [''],
      latitude: [''],
      longitude: [''],
      utcOffset: ['']
    });
    this.adminForm = this.fb.group({
      affiliateCode: [this.affiliateCode || '', [Validators.minLength(2), Validators.maxLength(10)]],
      firstName: [this.invitesData?.adminFirstName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      lastName: [this.invitesData?.adminLastName || '', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      newPassword: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      confirmPassword: ['', Validators.compose([
        Validators.required, Validators.minLength(2), Validators.maxLength(50),
        ConfirmFieldValidator.matchField('newPassword', 'passwordMismatch')
      ])],
      acceptTerms: [false, Validators.requiredTrue],
      cookiePolicy: [false]
    });

    this.locationService.searchCountries({filter: {supportedOnSystem: true}}).subscribe(v => {
      this.countries = v.results;
    });

    const inputElement = this.myInput.nativeElement;
    this.renderer2.selectRootElement(inputElement).focus();
    this.renderer2.setProperty(inputElement, 'value', ' ');
    this.renderer2.setProperty(inputElement, 'selectionStart', 1);
    this.renderer2.setProperty(inputElement, 'selectionEnd', 1);

    this.basicInfoForm.get('email')?.setAsyncValidators([this.deliverableEmailValidator.emailExistsValidator(), this.uniqueIdValidatorService.verifier('email', 'emailExists')]);
    this.adminForm.get('affiliateCode')?.setAsyncValidators([this.uniqueIdValidatorService.verifier('affiliate', 'affiliateCodeNotFound')]);
    this.basicInfoForm.get('phoneNumber')?.setAsyncValidators([this.uniqueIdValidatorService.phoneNumberVerifier()]);
    this.basicInfoForm.get('email')?.setValidators([Validators.required]);

    this.addressForm.get('country').valueChanges
      .pipe(takeUntil(this.destroy$))
      .pipe(distinctUntilChanged())
      .subscribe((countryId: string) => {
        this.loadingStates = true;
        if (!this.countryId) {
          this.states = null;
          return;
        }

        this.locationService.searchStates({filter: {country: this.countryId, name: this.googleState}})
          .subscribe((v: QueryResults) => {
            this.loadingStates = false;
            this.states = v.results;
            if (this.states) {
              this.cdr.detectChanges();
              this.addressForm.get('state').patchValue(this.states[0]?.name);
            }
          });
      });

    this.addressForm.get('state').valueChanges
      .pipe(takeUntil(this.destroy$))
      .pipe(distinctUntilChanged())
      .subscribe((stateCode: string) => {
        this.loadingCites = true;
        if (!stateCode) {
          this.cities = null;
          return;
        }
        this.locationService.searchCities({
          filter: {
            name: this.googleCity,
            stateName: stateCode
          }
        }).subscribe((v: QueryResultsCity) => {
          this.cities = v.results;
          this.cdr.detectChanges();
          this.addressForm.get('city').patchValue(this.cities[0]?.name);
        });
      });

    this.basicInfoForm.get('officeName').valueChanges.pipe(
    ).subscribe(newValue => {
      if (this.addressSelected) {
        this.resetForms();
        this.addressSelected = false;
      }
    });
  }


  onPasswordStrengthMessChanged($event: string) {
    if ($event == 'Poor') {
      this.adminForm.controls['newPassword'].setErrors({'notStrong': true});
    } else {
      this.removeErrorFromControl(this.adminForm.controls['newPassword']);
    }
  }

  removeErrorFromControl(control: any) {
    if (control.hasError('notStrong')) {
      delete control.errors['notStrong'];
      control.updateValueAndValidity();
    }
  }

  getPlacesAutocomplete(place: any) {
    this.reviews = [];
    this.basicInfoForm.get('officeName').patchValue(place.name);
    this.basicInfoForm.get('phoneNumber').patchValue(place.international_phone_number);
    this.basicInfoForm.get('officeAddress').patchValue(place.formatted_address);

    this.addressForm.get('latitude').patchValue(place?.geometry.location.lat());
    this.addressForm.get('longitude').patchValue(place?.geometry.location.lng());
    this.addressForm.get('utcOffset').patchValue(place?.utc_offset_minutes);

    place.reviews.forEach(v =>{
      if(v.rating >= 4 && v.language == 'en'){
        this.reviews.push({authorName: v.author_name, rating: v.rating, text: v.text, time: v.time});
      }
    });

    for (let i = 0; i < place?.address_components?.length; i++) {
      let addressType = place.address_components[i].types[0];
      if (addressType == 'country') {
        this.googleCountry = place.address_components[i].long_name;
      }
      if (addressType == 'administrative_area_level_1') {
        this.googleState = place.address_components[i].long_name;
      }
      if (addressType == 'locality') {
        this.googleCity = place.address_components[i].short_name;
      }
      if (addressType == 'postal_code') {
        this.addressForm.get('zipCode').patchValue(place.address_components[i].long_name)
      }
    }

    const country = this.countries.find(c => c.name == this.googleCountry);
    // console.log(country)
    this.country = country;

    this.addressForm.get('country').patchValue('');
    if (country) {
      this.countryId = country.id;
      this.addressForm.get('country').patchValue(country.alpha2);
    }
    this.addressSelected = true;

  }

  submittedForError(form: FormGroup) {
    console.log(form);
    form.markAllAsTouched();
    if (form.invalid) {
      this.errorSubmission = true;
      return;
    }

    if(!this.addressSelected){
        this.addressExists = false;
       return this.showErrorMessage('Currently, we only support addresses registered on Google Maps. Please add your business to Google Maps and retry. Thank you.');
      }
    this.errorSubmission = false;
  }

  get ProfessionalType() {
    return UserRegistrationDto.ProfessionalTypeEnum;
  }

  submit() {
    this.submittedForError(this.adminForm);

    if (!this.errorSubmission) {
      this.submitting = true;
      this.socialService.behaviorSubject.next('');
      sessionStorage.clear();

      this.createDentalOffice.createDentalOfficeWithAdmin(this.buildPayload()).subscribe(res => {
        this.submitting = false;
        this.loginUserManually(this.basicInfoForm.getRawValue().email, this.adminForm.getRawValue().newPassword, this.basicInfoForm.getRawValue().phoneNumber.e164Number)
        sessionStorage.setItem("REQUESTED_OTP", "TRUE");
      }, err => {
        this.submitting = false;
      })
    }
  }

  buildPayload(): CreateDentalOfficeWithAdminRequestParams {
    const basicInfo = this.basicInfoForm.getRawValue();
    const address = this.addressForm.getRawValue();
    const admin = this.adminForm.getRawValue();

    return {
      dentalOfficeWithAdminDto: {
        name: basicInfo.officeName,
        dentalOfficeEmail: basicInfo.email,
        officeType: basicInfo.officeType,
        inviteId: this?.invitesData ? +this.invitesData.code : null,
        specialityOffice: this.isSpecialist,
        utcOffsetSeconds: address.utcOffset,
        officeReviewDtos: this.reviews,
        address: {
          zipCode: address.zipCode,
          city: this.country?.name || 'United States',
          streetAddress: basicInfo.officeAddress,
          gpsCoordinate: {
            latitude: address?.latitude,
            longitude: address?.longitude
          }
        },
        adminUser: {
          firstName: admin.firstName,
          lastName: admin.lastName,
          email: basicInfo.email,
          mobileNumber: basicInfo.phoneNumber.e164Number,
          password: admin.newPassword,
          affiliateLinkCode: admin.affiliateCode,
          userType: UserRegistrationDto.UserTypeEnum.General,
        }
      }
    }
  }

  loginUserManually(username, password, phone) {
    this.userRegistration.login({loginDto: {username: username, password: password}}).subscribe(res => {
      this.socialService.save(res.accessToken);
      sessionStorage.setItem('keycloak_token', res.accessToken);
      this.showModal(
        AccountCreatedSuccessComponent, username, phone,
        'Account created successfully. A verification OTP has been sent to ',
        true, true);
    }, err => {})
  }

  showModal(component: any, email: string, phone: string, message?: string, isSuccess?: boolean, close?: boolean) {
    let bsModalRef = this.modalService.show(component, {
      initialState: {
        modalClass: 'modal-sm',
        message: message ? message : 'An email has been sent to you. Please check for an email from\n' +
          '        Dental Door and click on the link to activate your account.',
        email: email,
        accountType: "Dental Office",
        check: isSuccess,
        fail: !isSuccess,
        close: close,
        phone: phone
      },
      class: 'modal-lg modal-dialog-centered',
      keyboard: false,
      ignoreBackdropClick: true,
    });
    //@ts-ignore
    bsModalRef?.content?.onClick.subscribe(v => {
      this.router.navigate(['/verify-email'])
      bsModalRef.hide();
    });
  }

  onCheckboxChange(event: any) {
    this.selectedSpeciality = {
      officeType: this.selectedSpeciality ? this.selectedSpeciality.officeType : "",
      type: this.basicInfoForm.controls["officeType"] as FormArray
    }
    if (event.target.checked) {
      this.selectedSpeciality.type.push(new FormControl(event.target.value));
      this.selectedSpeciality.officeType = this.isSpecialistOffice(event.target.name);
    } else {
      const index = this.selectedSpeciality.type.controls.findIndex(
        (x) => x.value === event.target.value
      );
      this.selectedSpeciality.type.removeAt(index);
      if (this.selectedSpeciality.type.length == 0)
        this.selectedSpeciality.officeType = "";
    }

    if (event.target.value == 'General Dentistry' && this.selectedSpeciality.type.length > 0)
      return;
    this.isSpecialist = this.selectedSpeciality.officeType == "specialist";
    this.isGeneral = this.selectedSpeciality.officeType == "general";
  }

  isSpecialistOffice(officeType: string) {
    if (officeType == 'General Dentistry')
      return;
    return this.profession.concat(this.profession2).find((pro) => officeType == pro.name) ? "specialist" : "general";
  }

  schoolSelected(control: AbstractControl) {
    let value: Array<any> = control.value;
    let hasDental = value.find(item => item == 'Dental School');
    let hasHygiene = value.find(item => item == 'Dental Hygiene School')
    return hasDental && hasHygiene ? {schoolSelected: true} : null;
  }


  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 9000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  getSuccessMessage() {
    return this.successMessage;
  }


  private resetForms() {
    // Resetting only the fields that getPlacesAutocomplete sets
    // this.basicInfoForm.get('officeName').reset();
    this.basicInfoForm.get('phoneNumber').reset();

    this.basicInfoForm.get('officeAddress').reset();
    this.addressForm.get('latitude').reset();
    this.addressForm.get('longitude').reset();
    this.addressForm.get('utcOffset').reset();
    this.addressForm.get('zipCode').reset();
    this.addressForm.get('country').reset();

    this.reviews = [];
    this.googleCountry = null;
    this.googleState = null;
    this.googleCity = null;
    this.countryId = null;

  }
}
