import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as moment from "moment/moment";
import {environment} from "../../../environments/environment";

import {
  DentalReferralControllerService, Procedure, QueryResultsPojoReferralSearchPojo, ReferralRequestSearchFilter,
  ReferralSearchPojo
} from "dd-core-api-sdk";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {SelectionManager} from "dd-shared-lib";
import {BehaviorSubject, Observable, timer} from "rxjs";
import {Utils} from "../utils";
import {UserKeycloak} from "../../services/UserKeycloak";
import ReferralStatusConstantEnum = ReferralSearchPojo.ReferralStatusConstantEnum;
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationServiceKeycloak} from "../../services/AuthenticationServiceKeycloak";
import {PageChangedEvent} from "ngx-bootstrap/pagination";
import {getOffset, removeUndefinedOrNullFields} from "../../models/search-model";
import {ReferralStatusModalComponent} from "../referral-status-modal/referral-status-modal.component";
import {SocialService} from "../subject/SocialService";
import {OptionSelectionModalComponent} from "../option-selection-modal/option-selection-modal.component";
import {
  ResendNotificationModalComponent
} from "./resend-notification-modal/resend-notification-modal.component";
import {SuccessModalComponent} from "../success-modal/success-modal.component";

@Component({
  selector: 'app-referral-list',
  templateUrl: './referral-list.component.html',
  styleUrls: ['./referral-list.component.css']
})
export class ReferralListComponent implements OnInit, OnDestroy {

  @Input() isInbound: boolean;
  showErrorMessageTrigger = false;
  isError: boolean;
  errMessage = '';

  successMessage: any;
  showSuccessMessageTrigger: boolean;

  form: FormGroup;
  model: NgbDateStruct
  model1: NgbDateStruct
  selectionManager: SelectionManager<ReferralSearchPojo, number>;
  filterData: ReferralRequestSearchFilter = {};
  searching: boolean = false;
  resultArray: any[];
  limit = 10;
  offset: any;
  page: any;
  queryResults!: QueryResultsPojoReferralSearchPojo;
  resultSubject = new BehaviorSubject<QueryResultsPojoReferralSearchPojo>({});
  noteSubject = new BehaviorSubject<ReferralSearchPojo>({});
  data: any;
  referralStatusEnum = Utils.enumValues(ReferralStatusConstantEnum);
  referralStatus = ReferralSearchPojo.ReferralStatusConstantEnum;
  userAccount: UserKeycloak;
  intervalId: any;
  statusNote?: string;
  showInfo = {}
  noteLoading = false;
  currentStartDate: any;
  currentEndDate: any;
  destroyRunning = new BehaviorSubject<boolean>(false);
  utcOffsetMinutes: string;
   resending = false;

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private generalService: SocialService,
              private modalService: BsModalService,
              private authentication: AuthenticationServiceKeycloak,
              private dentalReferralController: DentalReferralControllerService) {

    this.utcOffsetMinutes = 'UTC'+this.getTimeZone().toString();

    this.referralStatusEnum = this.referralStatusEnum
      .filter(v => v != this.referralStatus.Custom && v != this.referralStatus.Rejected).sort();

    class ItemsSelect extends SelectionManager<any, any> {

      getIdentifier(e: any) {
        return e;
      }

      getState(e: any): number {
        return e.id;
      }

      isSelectable(e: any): boolean {
        return true;
      }
    }

    this.selectionManager = new ItemsSelect();
  }

  ngOnInit(): void {
    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;
    })

    this.form = this.fb.group({
      patientName: [''],
      referralStatus: [''],
      referralCode: [''],
      dentalOfficeToName: [''],
      startDate: [''],
      endDate: [''],
      limit: [''],
      offset: ['']
    });

    this.form.controls.startDate.valueChanges.subscribe(v => {
      this.currentStartDate = v;
      if (v != moment(v).format('YYYY-MM-DD')) {
        this.form.patchValue({endDate: ''});
      }
    });

    this.form.controls.endDate.valueChanges.subscribe(v => {
      if (moment(this.currentStartDate) > moment(v)) {
        this.showErrorMessage('Date(from) should be greater than Date(to).');
        this.form.patchValue({endDate: ''});
      }
    });

    this.onSearchClick();

    this.intervalId = setInterval(v => {
      this.onSearchClick();
    }, 7000)

  }
  trackByFn(index, item: ReferralSearchPojo) {
    return item.referralDataId;
  }

  getTimeZone() {
    var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  }

  maxYear() {
    return moment(new Date()).year();
  }

  maxMonth() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('M');
  }

  maxDay() {
    const check = moment(new Date(), 'YYYY/MM/DD');
    return check.format('D');
  }

  showErrorMessage(error: any) {
    this.errMessage = error;
    this.showErrorMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showErrorMessageTrigger = false;
    }, 4000);
  }

  getErrorMessage() {
    return this.errMessage;
  }

  onPageChange(event: PageChangedEvent) {
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(event.page, event.itemsPerPage);
    this.page = event.page;
    this.search(filter);
  }

  changeLimit($event: number) {
    this.limit = $event;
    let filter = this.getFilter($event);
    filter.offset = getOffset(0, $event);
    this.search(filter)
  }

  onSearchClick() {
    if (!this.form?.valid) {
      this.toastr.error('Form invalid');
      return;
    }
    let filter = this.getFilter(this.limit);
    filter.offset = getOffset(0, this.limit);

    this.search(filter);
  }

  search(filter: ReferralRequestSearchFilter) {

    this.selectionManager.clearSelection();
    this.searching = true;
    this.dentalReferralController.searchDentalReferral({filter: filter}).subscribe(v => {
      this.filterData = filter;
      Object.keys(this.filterData).forEach(v => {
        if (v == 'limit' || v == 'offset') {
          delete this.filterData![v]
        }
      })
      this.searching = false;
      this.offset = v.offset;
      this.queryResults = v;
      this.resultArray = v.results;
      this.resultSubject.next(v);
    }, () => {

    });

    this.data = filter;
    // this.data.page = this.page;
  }

  getFilter(limit?: number): ReferralRequestSearchFilter {
    const data = this.form!.value;

    let filter: ReferralRequestSearchFilter = {};
    filter.dentalOfficeToName = data.dentalOfficeToName;
    filter.referralStatusConstant = data.referralStatus;
    filter.referralCode = data.referralCode;
    filter.patientInfo = data.patientName;
    if (this.isInbound) {
      if (!this.userAccount?.dentalProfessional) {
        filter.dentalOfficeToId = AuthenticationServiceKeycloak._currentUserAccount?.otherData?.id
      }
      if (this.userAccount?.dentalProfessional) {
        filter.dentalProfessionalPortalUserId = this.userAccount?.dentalProfessional?.id;
      }
    } else {
      if (!this.userAccount?.dentalProfessional) {
        filter.dentalOfficeFromAccountId = AuthenticationServiceKeycloak._currentUserAccount?.accountId;
      }
      if (this.userAccount?.dentalProfessional) {
        filter.dentalProfessionalPortalUserId = this.userAccount?.dentalProfessional?.id;
      }
    }
    filter.startDate = data.startDate ? moment(data.startDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.endDate = data.endDate ? moment(data.endDate).subtract(1, 'months').format('YYYY-MM-DD') : undefined;
    filter.limit = limit || 10;
    return removeUndefinedOrNullFields(filter);
  }

  getAge(date) {
    const age = moment().diff(date, 'years');
    return age + ' year' + (age === 1 ? '' : 's') + ' old';
  }

  downloadReferralForm(referralDataId: number) {
    var a = document.createElement('a');
    a.href = environment.dentalDoorReportUrl + '/referral-form/' + referralDataId;
    a.target = '_blank';
    a.download = 'any';
    document.body.appendChild(a);
    a.click();
  }

  unfoldReasons(procedure: Procedure) {
    return procedure.procedureType;
  }

  viewNote(v) {
    this.showInfo[v] = true
  }

  hideNote(v) {
    this.showInfo[v] = false
  }

  showStatusNotes(referralSearchPojo: ReferralSearchPojo) {
    this.noteSubject.next(referralSearchPojo);

    let bsModalRef = this.modalService.show(ReferralStatusModalComponent, {
      initialState: {
        isInbound: this.isInbound,
        referralSearchPojo: this.noteSubject,
      },
      keyboard: false,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true,
    });

  }
  formatUTCMoment(dateCreated: string) {
    return moment(dateCreated).utc(true).toString();
  }

  resendNotification(referralRequestId, phoneNumber, email, type): void {
    this.resending = true;
    this.dentalReferralController.resendReferralNotification({referralRequestId,phoneNumber,email,type}).subscribe(res => {
      this.statusModal(phoneNumber,email,type);
    }, error => {
      this.showErrorMessage('Error sending notification');
    }).add(() => this.resending = false);

  }

  statusModal(phoneNumber: String, email: String, type: String) {
    let bsModalRef = this.modalService.show(SuccessModalComponent, {
      initialState: {
        body: 'Notification Resent Successfully',
        affiliate: false,
        close: 'true',
        buttonText: "Done",
        check: true
      },
      keyboard: false,
      backdrop: true,
      animated: true,
      class: 'modal-md modal-dialog-centered',
      ignoreBackdropClick: true
    });
    bsModalRef?.content.onDismissClicked.subscribe(v => {
      bsModalRef.hide();
    })
  }

  resendNotificationModal(referral: ReferralSearchPojo) {
    let bsModalRef = this.modalService.show(ResendNotificationModalComponent, {
      initialState: {
        referral: referral,
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered'
    });
    bsModalRef?.content?.onSubmitButtonClick.subscribe(resendDto => {
      bsModalRef.hide();
      this.resendNotification(resendDto.referralRequestId, resendDto.phoneNumber, resendDto.email,resendDto.type);
    });
    bsModalRef?.content?.onDismissButtonClick.subscribe(v => {
      bsModalRef.hide();
    });
  }


  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.destroyRunning.next(true);
  }


  showSuccessMessage(success: any) {
    this.successMessage = success;
    this.showSuccessMessageTrigger = true;
    window.scroll(0, 0);
    setTimeout(() => {
      this.showSuccessMessageTrigger = false;
    }, 5000);
  }

  getSuccessMessage() {
    return this.successMessage;
  }
}

export interface ResendNotificationDto {
  email: string,
  type: string,
  phoneNumber: string,
  referralRequestId: number
}
