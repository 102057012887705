/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CommentSearchFilter { 
    entityType: CommentSearchFilter.EntityTypeEnum;
    entityId: number;
    createdOnOrAfter?: string;
    createdBefore?: string;
    id?: number;
    email?: string;
    phoneNumber?: string;
    remainAnonymous?: boolean;
    edited?: boolean;
    type?: CommentSearchFilter.TypeEnum;
    limit?: number;
    note?: string;
    name?: string;
    offset?: number;
}
export namespace CommentSearchFilter {
    export type EntityTypeEnum = 'DentalOffice' | 'DentalProfessional' | 'Promotion' | 'Review' | 'FlashCardSet' | 'Comment';
    export const EntityTypeEnum = {
        DentalOffice: 'DentalOffice' as EntityTypeEnum,
        DentalProfessional: 'DentalProfessional' as EntityTypeEnum,
        Promotion: 'Promotion' as EntityTypeEnum,
        Review: 'Review' as EntityTypeEnum,
        FlashCardSet: 'FlashCardSet' as EntityTypeEnum,
        Comment: 'Comment' as EntityTypeEnum
    };
    export type TypeEnum = 'COMMENT' | 'DISPUTE';
    export const TypeEnum = {
        Comment: 'COMMENT' as TypeEnum,
        Dispute: 'DISPUTE' as TypeEnum
    };
}


