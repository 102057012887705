/**
 * Dental Application API
 * The Dental Door RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: developers@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Folder } from './folder';


export interface FlashCardSearchFilter { 
    numberOfFlashCardItems?: number;
    flashcardCategories?: Set<string>;
    createdByDentalProfessional?: boolean;
    createdByInstructor?: boolean;
    orderBy?: FlashCardSearchFilter.OrderByEnum;
    createdBy?: Set<number>;
    isFree?: boolean;
    minimumRating?: number;
    createdBefore?: string;
    createdOnOrAfter?: string;
    id?: number;
    title?: string;
    folder?: Folder;
    price?: number;
    published?: boolean;
    averageRating?: number;
    isPrivate?: boolean;
    limit?: number;
    description?: string;
    offset?: number;
}
export namespace FlashCardSearchFilter {
    export type OrderByEnum = 'RATING_ASC' | 'RATING_DESC' | 'DATE_CREATED_DESC';
    export const OrderByEnum = {
        RatingAsc: 'RATING_ASC' as OrderByEnum,
        RatingDesc: 'RATING_DESC' as OrderByEnum,
        DateCreatedDesc: 'DATE_CREATED_DESC' as OrderByEnum
    };
}


