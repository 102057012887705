import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {
  CreateDentalReferralRequestParams,
  DentalReferralControllerService,
  DentalReferralCreationDto,
  Procedure, SupportingDocumentType
} from "dd-core-api-sdk";
import {BsModalService} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import * as moment from "moment";
import {CountryISO, SearchCountryField} from "ngx-intl-tel-input";
import {Utils} from "../../../../shared/utils";
import PriorityLevelConstantEnum = DentalReferralCreationDto.PriorityLevelConstantEnum;
import {SocialService} from "../../../../shared/subject/SocialService";
import {ReferralService} from "../../../../shared/subject/referral.service";
import {CompletionModalComponent} from "../../../../shared/completion-modal/completion-modal.component";
import {RandomTrackingIdService} from "../../../../services/random-tracking-id.service";
import {AuthenticationServiceKeycloak} from "../../../../services/AuthenticationServiceKeycloak";
import {UserKeycloak} from "../../../../services/UserKeycloak";
import {UserAccountKeycloak} from "../../../../services/UserAccountKeycloak";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-preview-referral',
  templateUrl: './preview-referral.component.html',
  styleUrls: ['./preview-referral.component.css']
})
export class PreviewReferralComponent implements OnInit, OnChanges {
  @Input() formOneInputs: FormGroup;
  @Input() temporaryOffice: any;
  @Input() bwFileIdList: any;
  @Input() childTeethComponent: any;
  @Input() adultTeethComponent: any;
  @Output() referralSent: EventEmitter<boolean> = new EventEmitter<boolean>();
  formGroup: FormGroup;
  referralRequestId: number;
  @ViewChild('pdfView')
  pdfTable!: ElementRef;
  @Output() previousClicked = new EventEmitter<any>();
  preferredCountries: string[] = ['us'];
  SearchCountryField = SearchCountryField;
  countryISO: CountryISO.Nigeria;
  procedureList = Array<string>();
  priorityEnum = Utils.enumValues(PriorityLevelConstantEnum);
  patientName: any;
  @Input() affectedTeeth: string;
  adultTeethCheck: boolean = true;
  errorSubmission: boolean;
  showErrorMessageTrigger = false;
  errMessage = '';
  sending: boolean;
  officeToAddress: string | undefined;
  officeToEmail: string | undefined;
  officeToName: string | undefined;
  temporaryOfficeName: string;
  temporaryOfficeId: number;
  temporaryOfficeEmail: string;
  temporaryOfficeAddress: string;
  userAccount: UserKeycloak;
  currentAccount: UserAccountKeycloak;
  manualFirstName: string | undefined;
  manualLastName: string | undefined;
  fileDocs: any;
  pictureId = new BehaviorSubject<any>({id: ''});
  showAdultTeeth: boolean;

  constructor(private fb: FormBuilder,
              private modalService: BsModalService,
              private router: Router,
              private authentication: AuthenticationServiceKeycloak,
              private dentalReferralController: DentalReferralControllerService,
              private referralService: ReferralService) {
    this.priorityEnum = this.priorityEnum.filter(v => v != 'SEMI_URGENT');
  }


  ngOnInit(): void {

    this.formGroup = this.fb.group({
      patientLastName: [''],
      patientFirstName: [''],
      dateOfBirth: [''],
      phoneNumber: [''],
      preMedRequired: [''],
      priorityLevel: [''],
      referralNotes: [''],
      procedureType: [''],
      affectedToothRange: [''],
      xray: [''],
      xrayDate: [''],
      xrayFileName: [''],
      reasonForReferral: [''],
      referralTo: ['', []],
      referralFrom: ['', []],
      otherProcedures: [''],
      tissueType: [''],
      referralToOfficeId: [''],
      referralToProfId: [''],
      referredByOfficeAccountId: [''],
      referredByProfessionalId: [''],
      patientInsuranceCode:[''],
      googleOfficeName:[],
      googleOfficeStreetAddress:[],
      googleOfficePhone:[],
      externalReferralOfficePhone:[],
      externalReferralOfficeEmail:[],
      isExternalReferral:[]


    });

    this.formGroup.disable();

    this.authentication.getUser().subscribe(res => {
      this.userAccount = res;
      this.currentAccount = AuthenticationServiceKeycloak._currentUserAccount;
    });

    if (AuthenticationServiceKeycloak._currentUserAccount.otherData.displayPictureId) {
      this.pictureId.next({id: String(AuthenticationServiceKeycloak?._currentUserAccount?.otherData?.displayPictureId)});
    }
  }

  getReferralFormImage() {
    if (this.pictureId.value.id != null && this.pictureId.value.id != '') {
      return environment.siteUrl + "/files/" + this.pictureId.value.id;
    }
    return "./assets/img/logo-icon.svg";
  }


  ngOnChanges(simpleChanges: SimpleChanges) {

    this.referralService.referralCreationSubject.subscribe(res => {
      this.procedureList = [];
      this.adultTeethCheck = res?.isAdult;
      this.officeToAddress = res?.referralToAddress;
      this.officeToEmail = res?.referralToEmail;
      this.officeToName = res?.referralToName;
      this.fileDocs = res?.fileDocs;

      this.temporaryOfficeId = res?.temporaryToOfficeId;
      this.temporaryOfficeName = res?.temporaryOfficeName;
      this.temporaryOfficeEmail = res?.temporaryOfficeEmail;
      this.temporaryOfficeAddress = res?.temporaryOfficeAddress;
      this.manualFirstName = res?.manualFirstName;
      this.manualLastName = res?.manualLastName;

      this.formGroup?.patchValue({patientLastName: res?.lastNamePatient});
      this.formGroup?.patchValue({patientFirstName: res?.firstNamePatient});
      this.formGroup?.patchValue({dateOfBirth: this.format(res?.dateOfBirth)});
      this.formGroup?.patchValue({phoneNumber: res?.phoneNumber});
      this.formGroup?.patchValue({preMedRequired: res?.preMedRequired});
      this.formGroup?.patchValue({priorityLevel: res?.priorityLevel});
      this.formGroup?.patchValue({affectedToothRange: res?.affectedToothRange});
      this.formGroup?.patchValue({referralTo: res?.referralTo});
      this.formGroup?.patchValue({referralFrom: res?.referralFrom ? res?.referralFrom : ('Dr. ' + res?.manualFirstName + ' ' + res.manualLastName)});
      this.formGroup?.patchValue({isAdult: res?.isAdult});
      this.formGroup?.patchValue({referralNotes: res.referralNotes});
      this.formGroup?.patchValue({referralToOfficeId: res?.referralToOfficeId});
      this.formGroup?.patchValue({referralToProfId: res?.referralToProfId});
      this.formGroup?.patchValue({referredByOfficeAccountId: res?.referredByOfficeAccountId});
      this.formGroup?.patchValue({referredByProfessionalId: res?.referredByProfessionalId});
      this.formGroup?.patchValue({patientInsuranceCode:  res?.insuranceCode});
      this.formGroup?.patchValue({googleOfficeName: res?.googleOfficeName});
      this.formGroup?.patchValue({googleOfficeStreetAddress: res?.googleOfficeAddress});
      this.formGroup?.patchValue({googleOfficePhone:  res?.googleOfficePhone});

      this.formGroup?.patchValue({isExternalReferral:  res?.isExternalReferral});
      this.formGroup?.patchValue({externalReferralOfficePhone:  res?.externalReferralOfficePhone});
      this.formGroup?.patchValue({externalReferralOfficeEmail:  res?.externalReferralOfficeEmail});

      if (res?.procedureType?.length > 0) {
        res.procedureType.forEach(v => {
          this.procedureList.push(v.value);
        })
        this.procedureList = [...new Set(this.procedureList)];
      }
      this.formGroup?.patchValue({procedureType: JSON.stringify(this.procedureList).replace(/"/g, '')});
    })

  }

  getErrorMessage() {
    return this.errMessage;
  }

  submittedForError() {
    this.errorSubmission = true;
    this.formGroup.markAllAsTouched();
  }


  buildPayload(): CreateDentalReferralRequestParams {
    const value = this.formGroup.getRawValue();
    console.log('buildPayload value', value)
    let supportingDocList = Array<SupportingDocumentType>();

    this.bwFileIdList.forEach((item, index) => {
      supportingDocList.push({
        bwFileId: item
      });
    })
    return {
      dentalReferralCreationDto: {
        patientFirstName: value.patientFirstName,
        patientLastName: value.patientLastName,
        patientInsuranceCode: value.patientInsuranceCode,
        phoneNumber: value?.phoneNumber.e164Number,
        preMedRequired: value.preMedRequired,
        priorityLevelConstant: value?.priorityLevel,
        referralReasonNotes: value.referralNotes,
        procedureList: this.procedureList,
        patientId: value.patientId,
        portalAccountId: value.referredByOfficeAccountId != null ? +value.referredByOfficeAccountId : value.referredByOfficeAccountId,
        dateOfBirth: value.dateOfBirth,
        dentalProfessionalId: value.referredByProfessionalId != null ? +value.referredByProfessionalId : value.referredByProfessionalId,
        dentalOfficeIdTo: value.referralToOfficeId != null ? +value.referralToOfficeId : value.referralToOfficeId,
        dentalProfIdTo: value.referralToProfId != null ? +value.referralToProfId : value.referralToProfId,
        affectedToothRange: value.affectedToothRange,
        supportingDocuments: supportingDocList,
        patientImageUrl: value.patientImageUrl,
        externalReferral: value.isExternalReferral,
        consentGiven: value.consentGiven,
        adult: this.adultTeethCheck,
        temporaryDentalOfficeId: this.temporaryOfficeId,
        dentalProFromFirstName: this.manualFirstName,
        dentalProFromLastName: this.manualLastName,
        googleTemporaryOffice:{
          temporaryGoogleName: value.googleOfficeName,
          temporaryGoogleAddress: value.googleOfficeStreetAddress,
          temporaryGoogleEmail: value?.externalReferralOfficeEmail,
          temporaryGooglePhone: value.googleOfficePhone ? value.googleOfficePhone : value?.externalReferralOfficePhone?.e164Number
        }
      },
    };
  }

  submitReferralRequest() {
    // console.log(this.buildPayload());

    this.sending = true;
    this.dentalReferralController.createDentalReferral(this.buildPayload()).subscribe((res) => {
        this.referralRequestId = res.referralId;
        this.downloadReferralForm(this.referralRequestId)
        this.referralSent.emit(true);
        this.completionModal('Referral Sent!', 'The referral has been created successfully.', 'Return to dRefer', true, true, true);
        this.sending = false;
      }, (error) => {
        this.completionModal('Error!', 'Error in creating referral.', 'Dismiss', false, false, false);
        this.sending = false;
      }
    );
  }

  format(date: any) {
    if (!date) return '';

    return moment(date).format('MM/DD/YYYY');
  }

  submissionForError() {
    this.formOneInputs.markAllAsTouched();
    this.editReferralRequest();
  }

  editReferralRequest() {
    this.previousClicked.emit();
  }

  getFileURL(file, id) {
    const validExtensions = ["image/jpg", "image/jpeg", "image/png", "image/gif"];
    const isValidExtension = validExtensions.indexOf(file.contentType) > -1;

    if (isValidExtension) {
      return environment.siteUrl + "/files/" + id;
    }

    return "/assets/img/image.png";
  }

  completionModal(title, subTitle, buttonMessage, showPostReferral, shouldNavigate, isSuccess) {
    let bsModalRef = this.modalService.show(CompletionModalComponent, {
      initialState: {
        title: title,
        subTitle: subTitle,
        buttonText: buttonMessage,
        isSuccess: isSuccess,
        shouldNavigate: shouldNavigate,
        patientName: this.formGroup.getRawValue().patientFullName,
        professionalName: this.formOneInputs.getRawValue().referralTo,
        dentalOfficeName: this.formOneInputs.getRawValue().referredBy,
        referralRequestId: this.referralRequestId,
        showPostReferral: showPostReferral,
      },
      keyboard: false,
      backdrop: 'static',
      class: 'modal-sm modal-dialog-centered'
    });

    bsModalRef?.content?.onSuccessButtonClick.subscribe(v => {
      this.router.navigate(['/out-bound']);
    });
  }

  downloadReferralForm(referralRequestId: number) {
    var a = document.createElement('a');
    a.href = environment.dentalDoorReportUrl + '/referral-form/' + referralRequestId;
    a.target = '_blank';
    a.download = 'any';
    document.body.appendChild(a);
    a.click();
  }

  adultTeethToggle() {
    this.adultTeethCheck = !this.adultTeethCheck;
  }

  hasMixedTeethCheck(): boolean {
    let hasAdult = false;
    let hasChild = false;

    this.affectedTeeth?.split(',').forEach(tooth => {
      if (!isNaN(Number(tooth.trim()))) {
        hasAdult = true;
      } else {
        hasChild = true;
      }
    });

    return hasAdult && hasChild;
  }
}//
